var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TabCard",
    { attrs: { title: "Oplog User" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-data-table",
                {
                  staticClass: "elevation-1",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.userList,
                    loading: _vm.loading,
                    "hide-actions": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("td", [_vm._v(_vm._s(item.name))]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _vm._v(_vm._s(_vm.timeString(item.created)))
                          ]),
                          _c("td", { staticClass: "text-xs-right" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "44px"
                                }
                              },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { transition: "slide-x-transition" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      small: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("more_vert")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-tile",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.$refs.delUser.open({
                                                  content:
                                                    "Are you sure you want to delete the user ?",
                                                  item: item
                                                })
                                              }
                                            }
                                          },
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v("Delete")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "no-data",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "min-height": "192px",
                                position: "relative"
                              }
                            },
                            [
                              _c("Spin", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.loading,
                                    expression: "loading"
                                  }
                                ]
                              }),
                              _c(
                                "Empty",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.loading,
                                      expression: "!loading"
                                    }
                                  ]
                                },
                                [_c("span", [_vm._v("No oplog user created")])]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-progress-linear", {
                    attrs: { color: "blue", indeterminate: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "progress",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticStyle: { padding: "30px 0" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.addUser.open()
                    }
                  }
                },
                [_vm._v(" Create")]
              ),
              _c("AddOUser", { ref: "addUser", on: { confirm: _vm.addUser } }),
              _c(
                "Confirm",
                {
                  ref: "delUser",
                  on: { confirm: _vm.deleteUser },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function(options) {
                        return undefined
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("Delete User")
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }