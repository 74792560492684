<template>
    <TabCard title="Oplog User">
        <v-layout column>
            <v-flex>
                <v-data-table
                        :headers="headers"
                        :items="userList"
                        :loading="loading"
                        hide-actions
                        class="elevation-1">
                    <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                    <template v-slot:items="{item}">
                        <td>{{ item.name }}</td>
                        <td class="text-xs-right">{{ timeString(item.created)}}</td>
                        <td class="text-xs-right">

                                <span style="display:inline-block;width: 44px;">
                                    <v-menu transition="slide-x-transition">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon small v-on="on">
                                                <v-icon small>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-tile @click="$refs.delUser.open({content : 'Are you sure you want to delete the user ?', item:item})">
                                                <v-list-tile-title>Delete</v-list-tile-title>
                                            </v-list-tile>
                                        </v-list>
                                    </v-menu>
                                </span>
                        </td>
                    </template>
                    <template v-slot:no-data>
                        <div style="min-height: 192px;position: relative;" >
                            <Spin v-show="loading"></Spin>
                            <Empty v-show="!loading">
                                <span>No oplog user created</span>
                            </Empty>
                        </div>
                    </template>
                </v-data-table>
            </v-flex>
            <v-flex style="padding : 30px 0;">
                <v-btn class="text-none" color="primary" @click="$refs.addUser.open()"> Create</v-btn>
                <AddOUser ref="addUser" @confirm="addUser"></AddOUser>
                <Confirm ref="delUser" @confirm="deleteUser">
                    <span slot="title">Delete User</span>
                    <template v-slot:content="options"></template>
                </Confirm>
            </v-flex>
        </v-layout>
    </TabCard>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import {formatDate} from '@/module/utils/date'
    import AddOUser from './AddOUser.vue'
    import Confirm from '@/components/Confirm.vue'
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'
    import TabCard from '@/components/TabCard.vue'

    export default {
        name: "OplogUser",
        components: {AddOUser, Confirm ,Empty, Spin ,TabCard},
        data() {
            return {
                loading : false,
                headers: [
                    {text: 'Name', value: 'name', sortable: false, align: 'left'},
                    {text: 'Created on', value: 'created', sortable: false, align: 'right',},
                    {text: '', value: 'opt', sortable: false, align: 'right',},
                ],
                userList: []
            }
        },

        computed: {
            ...mapState('application', ['appInfo']),
            ...mapGetters('application', ['getServiceId']),
        },
        methods: {
            timeString(time) {
                return time ? formatDate(time) : ''
            },
            addUser({name, password}) {
                return this.$http.post('/db-users/', {
                    name,
                    password,
                    type: 'Oplog',
                    service_uuid: this.getServiceId(this.appInfo)
                }).then(() => {
                    this.$message.success('Your oplog user was created.')
                    this.getUserList()
                }).catch(() => {
                    this.$message.error('Oops, it failed to create the oplog user. You can contact us at support@cloudclusters.io for this issue.')
                })
            },
            getUserList() {
                if(this.loading){
                    return
                }
                this.loading =true
                this.$http.get(`/db-users/`, {
                    params: {
                        service_uuid: this.getServiceId(this.appInfo),
                        type : 'Oplog'
                    }
                }).then((res) => {
                    this.loading =false
                    this.userList = res.results
                }).catch(() => {
                    this.loading =false
                    this.$message.error('Oops,  failed to list the oplog users. You can reach us at support@cloudclusters.io')
                })
            },
            deleteUser({item}) {
                return this.$http.delete(`/db-users/${item.uuid}/`).then(() => {
                    this.$message.success('Your oplog user was deleted.')
                    this.getUserList()
                }).catch(() => {
                    this.$message.error('Oops, it failed to delete the database user. You can contact us at support@cloudclusters.io for this issue.')
                })
            },
        },
        created() {
            this.getUserList()
        }
    }
</script>

<style lang="scss">

</style>
