<template>
    <SmallBlock>
        <span slot="title" style="color:#333;font-size: 15px;font-weight: bold;">Current Plan Details</span>
        <v-layout row>
            <v-flex shrink style="min-width: 140px;" py-1 v-text="`${appInfo.plan_name} Plan :`"></v-flex>
            <v-flex grow py-1 style="min-height: 32px;width: 50%;"
                    v-text="`${parseInt(appInfo.plan.total_cpus)} CPU Core(s) / ${parseInt(appInfo.plan.memory_size)}GB RAM / ${parseInt(appInfo.plan.disk_size)}GB SSD / ${parseInt(appInfo.plan.backup_storage_size)}GB Backup`">
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex shrink style="min-width: 140px;" py-1>Current Add-ons :</v-flex>
            <v-flex grow py-1 style="min-height: 32px; width: 50%;">
<!--                {{alreadyAddons.cpu||0}} CPU Core(s) / {{alreadyAddons.memory||0}}GB RAM /{{alreadyAddons.disk*20||0}}GB SSD / {{alreadyAddons.backup_space*100||0}}GB Backup / {{alreadyAddons.dedicated_ip||0}}IP(s)-->
                                <span v-if="alreadyAddons.cpu">{{alreadyAddons.cpu}} CPU Core(s) </span>
                <span v-if="alreadyAddons.memory"><i v-if="alreadyAddons.cpu" style="font-style: normal">/</i> {{alreadyAddons.memory}}GB RAM </span>
                <span v-if="alreadyAddons.disk"><i v-if="alreadyAddons.memory||alreadyAddons.cpu"
                                                   style="font-style: normal">/</i> {{alreadyAddons.disk*20}}GB SSD </span>
                <span v-if="alreadyAddons.backup_space"><i
                        v-if="alreadyAddons.memory||alreadyAddons.cpu||alreadyAddons.disk"
                        style="font-style: normal">/</i> {{alreadyAddons.backup_space*100}}GB Backup </span>
                <span v-if="alreadyAddons.dedicated_ip"><i
                        v-if="alreadyAddons.backup_space||alreadyAddons.memory||alreadyAddons.cpu||alreadyAddons.disk"
                        style="font-style: normal">/</i> {{alreadyAddons.dedicated_ip}}IP(s)</span>
            </v-flex>
        </v-layout>
        <v-layout row v-for="(v , k) in currentPlan" :key="k">
            <v-flex shrink style="min-width: 140px;" py-1>{{k}} :</v-flex>
            <v-flex grow py-1 style="min-height: 32px; width: 50%;">{{v}}</v-flex>
        </v-layout>
    </SmallBlock>
</template>

<script>

    import SmallBlock from '@/components/SmallBlock'
    import {mapState} from 'vuex'
    import {formatDate} from '@/module/utils/date'

    export default {
        name: "CurrentPlan",
        components: {
            SmallBlock
        },
        data() {
            return {
                alreadyAddons: ''
            }
        },
        computed: {
            currentPlan() {
                return {
                    'Configuration': `${this.appInfo.cpu} CPU Core(s) / ${parseInt(this.appInfo.memory)}GB RAM / ${this.appInfo.disk}GB SSD / ${parseInt(this.appInfo.backup_storage)}GB Backup`,
                    'Billing Cycle': this.appInfo.price.duration_description,
                    'Recurring Amount': `$ ${this.appInfo.recurring_amount}`,
                    'Next Due Date': this.formatDate(this.appInfo.next_due_date)
                }
            },
            ...mapState('application', ['appInfo']),
        },
        methods: {
            formatDate,
            getAlreadyAddons() {
                this.$http.get(`/packages/${this.appInfo.uuid}/already_addons/`).then(res => {
                    this.alreadyAddons = res
                }).catch(err => {
                    this.$message.error(err.detail)
                })
            }
        },
        created() {
            this.getAlreadyAddons()
        }
    }
</script>

<style lang="scss">

</style>