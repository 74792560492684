<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
            <div style="width: 100%;height: 100%;position: relative;" v-if="dialog">
                <div style="position: sticky;top: 0;background: #fff;width: 100%;text-align: right;z-index:200;">
                    <v-tabs class="panel-tabs"
                            centered
                            fixed-tabs
                            ripple
                            v-model="active"
                            slider-color="primary">
                        <v-tab v-for="(tab, i) in ['Upgrade','Downgrade']" :key="i"
                               active-class="tab-active">
                            {{tab}}
                        </v-tab>
                    </v-tabs>
                </div>
                <v-card >
                    <v-card-text>
                        <transition
                                name="slide"
                                mode="out-in">
                            <componets ref="grade" :is="mod"></componets>
                        </transition>
                    </v-card-text>
                </v-card>
                <div style="position: sticky;bottom: 0;background: #fff;width: 100%;text-align: right;border-top: 1px solid #ccc;">
                    <v-btn color="blue darken-1" flat @click="close">close</v-btn>
                    <v-btn color="primary" @click="ok" :loading="loading">submit</v-btn>
                </div>
            </div>
        </v-dialog>
        <SuccessBox ref="successbox"></SuccessBox>
    </div>
</template>
<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import Upgrade from './Upgrade.vue'
    import Downgrade from './Downgrade.vue'
    import SuccessBox from '@/components/successBox/SuccessBox'
    import {mapState} from 'vuex'

    export default {
        name: "UpAndDown",
        mixins: [loadingMixin],
        components: {SuccessBox},
        data() {
            return {
                ready: true,
                active: 0,
            }
        },
        computed: {
            mod() {
                return this.active ? Downgrade : Upgrade
            },
            ...mapState('application', ['appInfo'])
        },
        methods: {
            ok() {
                const formData = this.$refs.grade.getFormData()
                if (formData){
                    !this.active?this.submitUpgrade(formData):this.submitDowngrade(formData)
                }
            },
            submitUpgrade(formData) {
                this.loading = true
                this.$http({
                    method: 'post',
                    url: `/packages/${this.appInfo.uuid}/upgrade/`,
                    data: formData
                }).then(res => {
                    this.loading = false
                    if (res.invoice_uuid) {
                        this.$router.push(`/invoice-detail/${res.invoice_uuid}`)
                    } else {
                        this.close()
                        this.$refs.successbox.open(1)
                    }
                }).catch((e) => {
                    this.loading = false
                    this.$message.error(e.detail)
                })
            },
            submitDowngrade(formData) {
                this.loading = true
                this.$http({
                    method: 'post',
                    url: `/packages/${this.appInfo.uuid}/downgrade/`,
                    data: formData
                }).then(() => {
                    this.loading = false
                    this.close()
                    this.$refs.successbox.open(1)
                }).catch((e) => {
                    this.loading = false
                    this.$message.error(e.detail)
                })
            }
        }
    }
</script>

<style lang="scss">

</style>
