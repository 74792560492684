var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "600px",
            "no-click-animation": ""
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm.dialog
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    height: "100%",
                    position: "relative"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "sticky",
                        top: "0",
                        background: "#fff",
                        width: "100%",
                        "text-align": "right",
                        "z-index": "200"
                      }
                    },
                    [
                      _c(
                        "v-tabs",
                        {
                          staticClass: "panel-tabs",
                          attrs: {
                            centered: "",
                            "fixed-tabs": "",
                            ripple: "",
                            "slider-color": "primary"
                          },
                          model: {
                            value: _vm.active,
                            callback: function($$v) {
                              _vm.active = $$v
                            },
                            expression: "active"
                          }
                        },
                        _vm._l(["Add", "Remove"], function(tab, i) {
                          return _c(
                            "v-tab",
                            { key: i, attrs: { "active-class": "tab-active" } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(tab) +
                                  "\n                    "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "", "pa-0": "" } },
                            [
                              _c(
                                "transiton",
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: { name: "slide", mode: "out-in" }
                                    },
                                    [
                                      _c(_vm.mod, {
                                        ref: "addons",
                                        tag: "componets"
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "sticky",
                        bottom: "0",
                        background: "#fff",
                        width: "100%",
                        "text-align": "right",
                        "border-top": "1px solid #ccc"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1 text-none", flat: "" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary text-none",
                            loading: _vm.loading
                          },
                          on: { click: _vm.ok }
                        },
                        [_vm._v("Submit")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("SuccessBox", { ref: "successbox" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }