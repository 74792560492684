<template>
    <v-container pa-0>
        <v-form>
            <v-layout column>
                <v-flex>
                    <p style="color: #666;font-size: 14px;">Please choose the add-on items and set the amount you'd like to add.
                        Then click the submit button. There will be about 1-3 minutes of downtime during the upgrade.<br>
                        <span v-if="appInfo.has_paypal_subscription">
                            After you update add-on configurations, the future recurring amount will be changed, followed by your PayPal subscription cancellation.
                            If you still need automatic payment through PayPal, please click “PayPal Subscription” on your next invoice.
                        </span>
                    </p>
                </v-flex>
                <v-flex>
                    <CurrentPlan></CurrentPlan>
                    <SmallBlock class="pt-2">
                        <span slot="title" style="color:#333;font-size: 15px;font-weight: bold;">New Add-ons</span>
                        <v-layout v-for="(v,k) in addOns" v-show="k!=='dedicated_ip'" style="height: 50px" :key="k" row>
                            <v-flex grow style="width: 300px;">
                                <v-checkbox v-model="v.flag" height="24" :disabled="!v.value.length" >
                                    <template v-slot:label><span style="font-size: 15px">{{v.description}}</span>
                                    </template>
                                </v-checkbox>
                            </v-flex>
                            <v-flex shrink ml-2 style="min-height: 32px;">
                                <v-autocomplete v-model="v.selected" height="24" :disabled="!v.flag"
                                                 hide-details dense :items="v.value">
                                    <template v-slot:item="{item}">
                                        <span>
                                            {{addonsUnit(item,v)}}
                                        </span>
                                    </template>
                                    <template v-slot:selection="{item}">
                                        <span>
                                            {{addonsUnit(item,v)}}
                                        </span>
                                    </template>
                                    <template v-slot:no-data>
                                        <span>
                                            Can't continue adding {{k}}
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </v-flex>
                        </v-layout>
                        <v-layout row mt-3>
                            <v-flex shrink style="min-width: 140px;min-height: 32px;" py-1>Recurring Amount :</v-flex>
                            <v-flex v-if="addonsRecurringAmount&&!loading" grow py-1 style="min-height: 32px;">$
                                {{addonsRecurringAmount}}
                            </v-flex>
                            <v-progress-circular v-if="loading" style="min-height: 32px;" indeterminate :width="3"
                                                 :size="25"></v-progress-circular>
                        </v-layout>
                        <v-layout row>
                            <v-flex style="min-width: 140px;min-height: 32px;" py-1 shrink>
                                Total Due Today
                                <v-tooltip bottom nudge-right="80">
                                    <template v-slot:activator="{ on }">
                                        <i class="fa fa-info-circle" aria-hidden="true" v-on="on"></i>
                                    </template>
                                    <span>The price differential before the app's next due date.</span>
                                </v-tooltip>
                                :
                            </v-flex>
                            <v-flex v-if="migrateFee && !loading" py-1 style="min-height: 32px;">$ {{migrateFee}}
                            </v-flex>
                            <v-progress-circular v-if="loading" style="min-height: 32px;" indeterminate :width="3"
                                                 :size="25"></v-progress-circular>
                        </v-layout>
                    </SmallBlock>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
    import SmallBlock from '@/components/SmallBlock'
    import CurrentPlan from '../_source/CurrentPlan'
    import {mapState} from 'vuex'

    export default {
        name: "AddPlan",
        components: {
            SmallBlock,
            CurrentPlan
        },
        data() {
            return {
                loading: false,
                addOns: {
                    cpu: {
                        selected: 0,
                        flag: false,
                        value: []
                    },
                    memory: {
                        selected: 0,
                        flag: false,
                        value: []
                    },
                    disk: {
                        selected: 0,
                        flag: false,
                        value: []
                    },
                    backup_space: {
                        selected: 0,
                        flag: false,
                        value: []
                    },
                    dedicated_ip: {
                        selected: 0,
                        flag: false,
                        value: []
                    }
                },
                migrateFee: '',
                addonsRecurringAmount: ''
            }
        },
        computed: {
            addonsUnit() {
                return (item, v) => {
                    switch (v.name) {
                        case 'cpu':
                            return item + ' Core(s)'
                        case 'memory':
                            return item + ' GB'
                        case 'disk':
                            return `${item}*${v.amount}GB`
                        case 'backup_space':
                            return `${item}*${v.amount}GB`
                        case 'dedicated_ip':
                            return item
                    }
                }
            },
            ...mapState('application', ['appInfo'])
        },
        watch: {
            addOns: {
                deep: true,
                handler() {
                    this.getAddonFee()
                }
            }
        },
        methods: {
            getAddonFee() {
                const formData = this.getFormData()
                if (!formData) {
                    this.addonsRecurringAmount = this.appInfo.recurring_amount
                    this.migrateFee = ''
                    return
                }
                this.loading = true
                this.$http.get(`/packages/${this.appInfo.uuid}/get_modify_fee/`, {
                    params: this.getFormData()
                }).then(res => {
                    this.loading = false
                    if (res.addon_fee > 2) {
                        this.migrateFee = res.addon_fee.toFixed(2)
                    } else if (res.addon_fee > 0) {
                        this.migrateFee = '0.00  The price differential before next due date is waived this time. The add-ons will be installed automatically once the order is submitted.'
                    } else {
                        this.migrateFee = '0.00  No price differential needed when your app is a free trial one. The add-ons will be installed automatically once the order is submitted.'
                    }
                    this.addonsRecurringAmount = res.addon_recurring_fee
                }).catch(err => {
                    this.loading = false
                    this.$message.error(err.detail)
                })
            },
            getPreModifyConfig() {
                this.$http.get(`/packages/${this.appInfo.uuid}/get_pre_addon_conditions/`).then(res => {
                    for (let item in res) {
                        res[item] = Object.assign(res[item], {
                            selected: 0,
                            flag: false
                        })
                    }
                    this.addOns = res
                }).catch(err => {
                    this.$message.error(err.detail)
                })
            },
            getFormData() {
                const formData = {
                    cpu: this.addOns.cpu.flag ? this.addOns.cpu.selected : 0,
                    memory: this.addOns.memory.flag ? this.addOns.memory.selected : 0,
                    disk: this.addOns.disk.flag ? this.addOns.disk.selected : 0,
                    backup_space: this.addOns.backup_space.flag ? this.addOns.backup_space.selected : 0,
                    dedicated_ip: this.addOns.dedicated_ip.flag ? this.addOns.dedicated_ip.selected : 0,
                    cpu_unit_price_id: this.addOns.cpu.id,
                    memory_unit_price_id: this.addOns.memory.id,
                    disk_unit_price_id: this.addOns.disk.id,
                    backup_space_unit_price_id: this.addOns.backup_space.id,
                    dedicated_ip_unit_price_id: this.addOns.dedicated_ip.id
                }
                if (!formData.cpu && !formData.memory && !formData.backup_space && !formData.dedicated_ip && !formData.disk) {
                    return false
                } else {
                    return formData
                }
            }
        },
        created() {
            this.getPreModifyConfig()
        }
    }
</script>

<style lang="scss">

</style>
