<template>
    <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
        <v-form v-model="valid" ref="form" lazy-validation v-if="dialog">
            <v-card>
                <v-card-title>
                    <span class="title">Cancel My Service</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-stepper v-model="step" class="elevation-0">
                        <v-stepper-header>
                            <v-stepper-step :complete="step > 1" step="1">Start cancellation</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 2" step="2">Confirm cancellation</v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1" class="pa-0">
                                <v-card>
                                    <v-container fluid pa-0 grid-list-lg>
                                        <p style="color:#666;">You are about to cancel our cloud application hosting
                                            service ({{appInfo.name}}).
                                            This means that all data and settings of this application will be discarded.
                                            This action cannot be undone.
                                        </p>
                                        <v-text-field
                                                v-model="formData.name"
                                                :rules="[rules.required ,rules.same]"
                                                :height="30"
                                                required
                                                persistent-hint
                                                hint="Please enter the deployment name to continue the deletion"
                                                clearable>
                                            <template v-slot:label>
                                                <RequiredDot>Name</RequiredDot>
                                            </template>
                                        </v-text-field>
                                        <v-select
                                                v-if="!isFree"
                                                dense
                                                return-object
                                                item-text="name"
                                                v-model="formData.requestType"
                                                :rules="[rules.required]"
                                                :items="cancellationTypes">
                                            <template v-slot:label>
                                                <RequiredDot>Cancellation Type</RequiredDot>
                                            </template>
                                        </v-select>
                                    </v-container>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-content step="2" class="pa-0">
                                <v-card>
                                    <v-container fluid pa-0 grid-list-lg>
                                        <v-layout column></v-layout>
                                        <v-flex>
                                            <p style="color:#666; text-align: center; font-size: 16px;">
                                                We really apprecite if you take a moment to help us with a quick
                                                feedback.
                                            </p>
                                            <p style="color:#000; text-align: center; font-size: 14px;">
                                                Would you tell us why you decide to cancel the service?
                                            </p>
                                        </v-flex>
                                        <v-flex class="px-4">
                                            <v-item-group  v-model="selectedTags"  class="step-cancel-modal">
                                                <v-item
                                                        v-for="(item,i) in tagList"
                                                        :key="i">
                                                    <v-chip
                                                            slot-scope="{ active, toggle }"
                                                            :selected="active"
                                                            @click="toggle">
                                                        {{item.description}}
                                                    </v-chip>
                                                </v-item>
                                            </v-item-group>
                                        </v-flex>
<!--                                        <v-flex class="px-4">-->
<!--                                            <v-switch-->
<!--                                                    v-model="other"-->
<!--                                                    label="Something else to write ?"></v-switch>-->
<!--                                        </v-flex>-->
                                        <v-flex class="px-4 mt-4" v-if="step===2">
                                            <v-textarea
                                                    solo
                                                    :placeholder="selectedTags!==null?tagList[selectedTags].cancellation_description:'Description'"
                                                    :rules="[rules.required]"
                                                    v-model="formData.description"
                                                    label="Description"
                                            ></v-textarea>
                                        </v-flex>
                                    </v-container>
                                </v-card>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <template v-if="step===1">
                        <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                        <v-btn class="text-none" color="primary" @click="nextStep(2)">Next</v-btn>
                    </template>
                    <template v-if="step===2">
                        <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                        <v-btn class="text-none" color="primary" :disabled="!disBtn" @click="ok" :loading="loading">Submit</v-btn>
                    </template>

                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import RequiredDot from '@/components/RequiredDot.vue'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import rules from '@/module/rules/index.js'
    import {cancellationTypes} from '@/module/constant'


    import {mapState} from 'vuex' //mapState  mapGetters mapActions mapGetters

    export default {
        name: "StepCancelModal",
        mixins: [loadingMixin],
        components: {RequiredDot},
        props : ['isFree'],
        data() {
            return {
                cancellationTypes,
                step: 1,
                // other: false,
                tagList: [],
                selectedTags: null,
                rules: {
                    ...rules,
                    same: value => value === this.appInfo.name || "The entered name doesn't match ",
                },
                formData: {
                    name: '',
                    description: '',
                    requestType: ''
                },
            }
        },
        computed: {
            disBtn(){
                return typeof this.selectedTags === 'number' && this.formData.description
            },
            ...mapState('application', ['appInfo'])
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.$refs.form.reset()
                    this.step = 1
                    this.selectedTags = null
                    this.formData = {
                        reasons: [],
                        name: '',
                        description: '',
                        requestType: '',
                        need_check: 0
                    }
                }
            }
        },
        methods: {
            nextStep(step) {
                if(this.$validator()){
                    this.step = step
                }
            },
            $validator() {
                // this.formData.reasons = this.selectedTags.map((index) => {
                //     return this.tagList[index].id
                // })
                if (this.selectedTags !== null) {
                    this.formData.reasons = [this.tagList[this.selectedTags].id]
                    this.formData.need_check = this.tagList[this.selectedTags].need_check
                }
                return this.$refs.form.validate()
            },
            getReasons(){
                this.$http.get('/cancellation-reasons/').then((res) => {
                    this.tagList = res.results
                })
            }
        },
        created() {
            this.getReasons()
        }
    }
</script>

<style lang="scss">
    .step-cancel-modal {
        .v-chip--selected {
            background-color: #3F51B5;
            color: #fff;
        }
    }
</style>
