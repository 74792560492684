<template>
  <v-dialog v-model="dialog" width="500" no-click-animation>
    <v-card>
      <v-card-title>Change Payment Method</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout column>
          <v-flex md12>
            Change the default payment method for the application.
            <span v-if="appInfo.has_paypal_subscription">
              After you change the payment method, your PayPal subscription will be canceled to prevent duplicate charges. If you still need automatic payment through PayPal, please click “PayPal Subscription” on your next invoice.
            </span>
          </v-flex>
          <v-flex md12>
            <v-radio-group  v-if="items" v-model="formData.payment_method" >
              <v-radio  v-for="(item,index) in items" :key="index" :value="item" color="primary">
                <template v-slot:label>
                  <span>
                        {{item}}
                  </span>
                </template>
              </v-radio>
            </v-radio-group>
            <div v-else  style="display: flex;align-items: center;justify-content:center;min-height: 132px;">
              <v-progress-circular  indeterminate  color="blue"></v-progress-circular>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small class="ml-3 text-none" color="blue darken-1" flat @click="close">Cancel</v-btn>
        <v-btn small class="text-none" color="primary" :loading="loading" @click="ok">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import dialogMixin from '@/module/mixins/loading.mixin'
  import {mapState} from 'vuex'

  export default {
    name: 'PaymentMethod',
    mixins: [dialogMixin],
    data() {
      return {
        items: ['Credit Card','PayPal'],
        formData:{
          payment_method:""
        }
      }
    },
    computed: {
      ...mapState('application', ['appInfo'])
    },
    watch:{
      dialog(){
        if(!this.dialog){
          this.formData.payment_method = ''
        }
      }
    },

    methods: {
      open() {
        this.formData.payment_method = this.appInfo.payment_method
        this.dialog = true
      },
      $validator() {
        return !!this.formData.payment_method
      }
    }
  }
</script>