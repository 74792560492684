<template>
    <TabCard title="Scaling">
        <v-layout column >
            <v-flex  style="text-align: left;">
                <p class="fs-3 font-weight-regular" style="color: rgb(102, 102, 102); ">
                    Upgrade/Downgrade your plan to fit your business needs.
                </p>
                <v-btn  :title="`Upgrade/Downgrade my plan to fit your business needs.`" class="ma-0 text-none" color="primary" :disabled="!userType || userType==='Fraud'" @click="openDialog(0)">Upgrade/Downgrade</v-btn>
            </v-flex>
            <v-flex  style="text-align: left;">
                <p class="fs-3 font-weight-regular" style="color: rgb(102, 102, 102); ">
                    Add/Remove additional CPU cores, memory, disk space, and backup space.
                </p>
                <v-btn :title="`Add/Remove additional CPU cores, memory, disk space, and backup space.`" class="ma-0 text-none" color="primary" :disabled="!userType || userType==='Fraud'" @click="openDialog(1)">Add-ons</v-btn>
            </v-flex>
            <v-flex>
                <p class="fs-3 font-weight-regular">Need more resources and better performance for your project?<a style="text-decoration: none;" target="_blank" href="https://www.kubeclusters.com/"> Try our fully managed Kubernetes hosting.</a>
                    </p>
            </v-flex>
        </v-layout>
        <UpGrade ref="upgrade"></UpGrade>
        <AddOns ref="addons"></AddOns>
        <Confirm ref="notGoodConfirm" @confirm="toProfile">
            <span slot="title">Complete Billing Information</span>
            <template v-slot:content>
                <span>A paid account is needed to operate this action. Please submit the billing information to activate your paid account.</span>
            </template>
            <template v-slot:footer>
                <v-btn class="text-none" color="blue darken-1" flat @click="$refs.notGoodConfirm.close()">Later</v-btn>
                <v-btn class="text-none" color="primary" @click="toProfile">Do it now</v-btn>
            </template>
        </Confirm>
    </TabCard>

</template>

<script>
    import Confirm from '@/components/Confirm'
    import TabCard from '@/components/TabCard.vue'
    // import SmallBlock from '@/components/SmallBlock'
    import UpGrade from './upgrade/index.vue'
    import AddOns from './addOns/index.vue'
    import {mapState,mapActions} from 'vuex'


    export default {
        name: "Scaling",
        components: { UpGrade, AddOns, Confirm ,TabCard},
        data() {
            return {
                userType: ''
            }
        },
        computed: {
            planDuration() {
                return (duration) => {
                    switch (duration) {
                        case 1:
                            return 'Month'
                        case 3:
                            return 'quarter'
                        case 6:
                            return '6 Months'
                        case 12:
                            return 'Year'
                        case 24:
                            return '2 Years'
                    }
                }
            },
            ...mapState('application', ['appInfo']),
        },
        methods: {
            openDialog(from) {
                if (this.userType !== 'Good') {
                    this.$refs.notGoodConfirm.open()
                } else {
                    from ? this.$refs.addons.open() : this.$refs.upgrade.open()
                }
            },
            toProfile() {
                this.$refs.notGoodConfirm.close()
                this.$root.billingCpn.open()
            },
            ...mapActions('client', ['getFreeTrialStatus'])
        },
        created() {
            this.getFreeTrialStatus().then(res => {
                this.userType = res.status
            }).catch(e => (
                this.$message.error(e.detail)
            ))
        }
    }
</script>

<style lang="scss">

</style>
