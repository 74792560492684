<template>
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title class="text-center">Check</v-card-title>
            <v-card-text></v-card-text>
            <v-card-actions></v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'


    export default {
        name: "appCheck",
        mixins:[loadingMixin]
    }
</script>

<style lang="scss">

</style>