<template>
    <component :is="currentMod"></component>
</template>

<script>
    // import DataBaseOverview from './DataBaseOverview.vue'
    import Overview from './Overview.vue'
    import {mapState} from 'vuex'

    export default {
        name: "Overview",
        computed: {
            currentMod() {
                return Overview
                // let num =this.appInfo.category
                // return num === 'Database' ?DataBaseOverview: ['CMS','Ecom'].indexOf(num) !== -1 ?WpOverview:WpOverview
            },
            ...mapState('application', ['appInfo'])
        },
        created() {
            this.$root.$emit('showGuidance')
            this.$SDK.track({pageName:'Overview',productName:this.appInfo.product_name})
        }
    }
</script>
