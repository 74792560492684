var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TabCard",
    { attrs: { title: "Scaling" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                "p",
                {
                  staticClass: "fs-3 font-weight-regular",
                  staticStyle: { color: "rgb(102, 102, 102)" }
                },
                [
                  _vm._v(
                    "\n                Upgrade/Downgrade your plan to fit your business needs.\n            "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-0 text-none",
                  attrs: {
                    title:
                      "Upgrade/Downgrade my plan to fit your business needs.",
                    color: "primary",
                    disabled: !_vm.userType || _vm.userType === "Fraud"
                  },
                  on: {
                    click: function($event) {
                      return _vm.openDialog(0)
                    }
                  }
                },
                [_vm._v("Upgrade/Downgrade")]
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                "p",
                {
                  staticClass: "fs-3 font-weight-regular",
                  staticStyle: { color: "rgb(102, 102, 102)" }
                },
                [
                  _vm._v(
                    "\n                Add/Remove additional CPU cores, memory, disk space, and backup space.\n            "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-0 text-none",
                  attrs: {
                    title:
                      "Add/Remove additional CPU cores, memory, disk space, and backup space.",
                    color: "primary",
                    disabled: !_vm.userType || _vm.userType === "Fraud"
                  },
                  on: {
                    click: function($event) {
                      return _vm.openDialog(1)
                    }
                  }
                },
                [_vm._v("Add-ons")]
              )
            ],
            1
          ),
          _c("v-flex", [
            _c("p", { staticClass: "fs-3 font-weight-regular" }, [
              _vm._v(
                "Need more resources and better performance for your project?"
              ),
              _c(
                "a",
                {
                  staticStyle: { "text-decoration": "none" },
                  attrs: {
                    target: "_blank",
                    href: "https://www.kubeclusters.com/"
                  }
                },
                [_vm._v(" Try our fully managed Kubernetes hosting.")]
              )
            ])
          ])
        ],
        1
      ),
      _c("UpGrade", { ref: "upgrade" }),
      _c("AddOns", { ref: "addons" }),
      _c(
        "Confirm",
        {
          ref: "notGoodConfirm",
          on: { confirm: _vm.toProfile },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function() {
                return [
                  _c("span", [
                    _vm._v(
                      "A paid account is needed to operate this action. Please submit the billing information to activate your paid account."
                    )
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { color: "blue darken-1", flat: "" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.notGoodConfirm.close()
                        }
                      }
                    },
                    [_vm._v("Later")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { color: "primary" },
                      on: { click: _vm.toProfile }
                    },
                    [_vm._v("Do it now")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Complete Billing Information")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }