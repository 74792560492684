var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper panel-overview pa-0",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _vm.showConnection
            ? _c("v-flex", [_c("MysqlConnectionInfo")], 1)
            : _vm._e(),
          _c("v-flex", [_c("BaseInfo")], 1),
          _vm.showOplogUser ? _c("v-flex", [_c("OplogUser")], 1) : _vm._e(),
          _c("v-flex", [_c("RealStatus")], 1),
          _c("v-flex", [_c("Scaling")], 1),
          _vm.appInfo.product_name.toLowerCase() !== "cockroachdb"
            ? _c("v-flex", [_c("Reinstall")], 1)
            : _vm._e(),
          _c("v-flex", [_c("Cancellation")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }