var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tab-card",
    { attrs: { title: "Reinstallation" } },
    [
      _c(
        "p",
        {
          staticClass: "fs-3 font-weight-regular",
          staticStyle: { color: "#666" }
        },
        [
          _vm._v(
            "\n        A  Reinstallation will erase all data of the application. Please make sure you have a backup of your data on your local machine.\n    "
          )
        ]
      ),
      _c(
        "v-btn",
        {
          staticClass: "ma-0 text-none",
          attrs: { color: "error" },
          on: {
            click: function($event) {
              return _vm.$refs.install.open()
            }
          }
        },
        [_vm._v("Install")]
      ),
      _c("InstallConfirm", {
        ref: "install",
        on: { confirm: _vm.installOrRefactory }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }