var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TabCard",
    { attrs: { title: "Stop Using The Application" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c("v-flex", [
            !_vm.hadRequest
              ? _c(
                  "p",
                  {
                    staticClass: "fs-3 font-weight-regular",
                    staticStyle: { color: "#666", margin: "0" }
                  },
                  [
                    _vm._v(
                      "Before terminating the deployment, please make sure that you have exported your data or backed up them on your local machine."
                    )
                  ]
                )
              : _c(
                  "p",
                  {
                    staticClass: "fs-3 font-weight-regular",
                    staticStyle: { color: "#666", margin: "0" }
                  },
                  [
                    _vm._v(
                      "You have submitted a cancellation request for the service. If you want to continue to use the serivce, please delete the request."
                    )
                  ]
                )
          ]),
          _vm.ready
            ? _c(
                "v-flex",
                { staticStyle: { padding: "10px 0" } },
                [
                  !_vm.hadRequest
                    ? [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-none",
                            attrs: {
                              title:
                                _vm.isFree === "Delete Deployment"
                                  ? "Stop using the deployment."
                                  : "Submit a request to cancel the application.",
                              color: "error"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$refs.cancellation.open()
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isFree
                                    ? "Delete Deployment"
                                    : "Request Cancellation"
                                )
                            )
                          ]
                        )
                      ]
                    : [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-none",
                            attrs: { color: "error" },
                            on: {
                              click: function($event) {
                                return _vm.$refs.cancelSubmit.open()
                              }
                            }
                          },
                          [_vm._v("Delete Cancellation Request")]
                        )
                      ]
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _c("StepCancelModal", {
        ref: "cancellation",
        attrs: { "is-free": _vm.isFree },
        on: { confirm: _vm.cancel }
      }),
      _c(
        "Confirm",
        {
          ref: "cancelSubmit",
          on: { confirm: _vm.cancelSubmit },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function() {
                return [
                  _vm._v(
                    "\n            Are you sure you want to cancel the cancellation request for "
                  ),
                  _c("span", { style: { color: _vm.$vuetify.theme.primary } }, [
                    _vm._v(_vm._s(_vm.appInfo.name))
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Confirmation")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }