<template>
    <div class="small-block">
        <div class="text-title font-weight-bold fs-4" v-if="title || $slots.title"><slot name="title"><span v-url v-html="title"></span></slot></div>
        <div class="text-content fs-4"><slot></slot></div>
    </div>
</template>

<script>
    export default {
        name: "SmallBlock",
        props : ['title']
    }
</script>

<style lang="scss">
    .small-block{
        .text-title{
            color: #444;
            min-height: 30px;
            line-height: 20px;
            padding: 0;
        }
        .text-content{
            color: #666;
        }
    }

</style>
