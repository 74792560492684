var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm.dialog
        ? _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Cancel My Service")
                    ])
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-stepper",
                        {
                          staticClass: "elevation-0",
                          model: {
                            value: _vm.step,
                            callback: function($$v) {
                              _vm.step = $$v
                            },
                            expression: "step"
                          }
                        },
                        [
                          _c(
                            "v-stepper-header",
                            [
                              _c(
                                "v-stepper-step",
                                {
                                  attrs: { complete: _vm.step > 1, step: "1" }
                                },
                                [_vm._v("Start cancellation")]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-stepper-step",
                                {
                                  attrs: { complete: _vm.step > 2, step: "2" }
                                },
                                [_vm._v("Confirm cancellation")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-items",
                            [
                              _c(
                                "v-stepper-content",
                                { staticClass: "pa-0", attrs: { step: "1" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-container",
                                        {
                                          attrs: {
                                            fluid: "",
                                            "pa-0": "",
                                            "grid-list-lg": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticStyle: { color: "#666" } },
                                            [
                                              _vm._v(
                                                "You are about to cancel our cloud application hosting\n                                            service (" +
                                                  _vm._s(_vm.appInfo.name) +
                                                  ").\n                                            This means that all data and settings of this application will be discarded.\n                                            This action cannot be undone.\n                                        "
                                              )
                                            ]
                                          ),
                                          _c("v-text-field", {
                                            attrs: {
                                              rules: [
                                                _vm.rules.required,
                                                _vm.rules.same
                                              ],
                                              height: 30,
                                              required: "",
                                              "persistent-hint": "",
                                              hint:
                                                "Please enter the deployment name to continue the deletion",
                                              clearable: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function() {
                                                    return [
                                                      _c("RequiredDot", [
                                                        _vm._v("Name")
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              false,
                                              3638332957
                                            ),
                                            model: {
                                              value: _vm.formData.name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.name"
                                            }
                                          }),
                                          !_vm.isFree
                                            ? _c("v-select", {
                                                attrs: {
                                                  dense: "",
                                                  "return-object": "",
                                                  "item-text": "name",
                                                  rules: [_vm.rules.required],
                                                  items: _vm.cancellationTypes
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function() {
                                                        return [
                                                          _c("RequiredDot", [
                                                            _vm._v(
                                                              "Cancellation Type"
                                                            )
                                                          ])
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1008696181
                                                ),
                                                model: {
                                                  value:
                                                    _vm.formData.requestType,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      "requestType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData.requestType"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-stepper-content",
                                { staticClass: "pa-0", attrs: { step: "2" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-container",
                                        {
                                          attrs: {
                                            fluid: "",
                                            "pa-0": "",
                                            "grid-list-lg": ""
                                          }
                                        },
                                        [
                                          _c("v-layout", {
                                            attrs: { column: "" }
                                          }),
                                          _c("v-flex", [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  color: "#666",
                                                  "text-align": "center",
                                                  "font-size": "16px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                We really apprecite if you take a moment to help us with a quick\n                                                feedback.\n                                            "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  color: "#000",
                                                  "text-align": "center",
                                                  "font-size": "14px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Would you tell us why you decide to cancel the service?\n                                            "
                                                )
                                              ]
                                            )
                                          ]),
                                          _c(
                                            "v-flex",
                                            { staticClass: "px-4" },
                                            [
                                              _c(
                                                "v-item-group",
                                                {
                                                  staticClass:
                                                    "step-cancel-modal",
                                                  model: {
                                                    value: _vm.selectedTags,
                                                    callback: function($$v) {
                                                      _vm.selectedTags = $$v
                                                    },
                                                    expression: "selectedTags"
                                                  }
                                                },
                                                _vm._l(_vm.tagList, function(
                                                  item,
                                                  i
                                                ) {
                                                  return _c("v-item", {
                                                    key: i,
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var active =
                                                              ref.active
                                                            var toggle =
                                                              ref.toggle
                                                            return _c(
                                                              "v-chip",
                                                              {
                                                                attrs: {
                                                                  selected: active
                                                                },
                                                                on: {
                                                                  click: toggle
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                        " +
                                                                    _vm._s(
                                                                      item.description
                                                                    ) +
                                                                    "\n                                                    "
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.step === 2
                                            ? _c(
                                                "v-flex",
                                                { staticClass: "px-4 mt-4" },
                                                [
                                                  _c("v-textarea", {
                                                    attrs: {
                                                      solo: "",
                                                      placeholder:
                                                        _vm.selectedTags !==
                                                        null
                                                          ? _vm.tagList[
                                                              _vm.selectedTags
                                                            ]
                                                              .cancellation_description
                                                          : "Description",
                                                      rules: [
                                                        _vm.rules.required
                                                      ],
                                                      label: "Description"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formData
                                                          .description,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.description"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm.step === 1
                        ? [
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-none",
                                attrs: { color: "blue darken-1", flat: "" },
                                on: { click: _vm.close }
                              },
                              [_vm._v("Close")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-none",
                                attrs: { color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.nextStep(2)
                                  }
                                }
                              },
                              [_vm._v("Next")]
                            )
                          ]
                        : _vm._e(),
                      _vm.step === 2
                        ? [
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-none",
                                attrs: { color: "blue darken-1", flat: "" },
                                on: { click: _vm.close }
                              },
                              [_vm._v("Close")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-none",
                                attrs: {
                                  color: "primary",
                                  disabled: !_vm.disBtn,
                                  loading: _vm.loading
                                },
                                on: { click: _vm.ok }
                              },
                              [_vm._v("Submit")]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }