var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "small-block" }, [
    _vm.title || _vm.$slots.title
      ? _c(
          "div",
          { staticClass: "text-title font-weight-bold fs-4" },
          [
            _vm._t("title", [
              _c("span", {
                directives: [{ name: "url", rawName: "v-url" }],
                domProps: { innerHTML: _vm._s(_vm.title) }
              })
            ])
          ],
          2
        )
      : _vm._e(),
    _c("div", { staticClass: "text-content fs-4" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }