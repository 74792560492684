<template>
    <TabCard title="Overview" class="base-info-block">
        <v-layout wrap>
            <v-flex xs12 sm12 md6 v-for="(item,i ) in baseInfo" :key="i">
                <SmallBlock>
                    <span slot="title">{{item.title}}</span>
                    <div class="info-content ">
                        <span class="info-msg">{{item.content}}</span>
                        <span class="info-ico" v-if="item.handler" @click="item.handler">
                            <v-tooltip top>
								<template v-slot:activator="{ on }">
                                     <v-icon  v-on="on" color="primary" size="20">fa fa-pencil-square-o</v-icon>
								</template>
								<span>{{item.tip || 'change'}}</span>
							</v-tooltip>

                        </span>
                    </div>
                </SmallBlock>
            </v-flex>
            <v-flex xs12 class="pl-0">
                <AppBtn :app="appInfo"></AppBtn>
            </v-flex>
        </v-layout>
        <ChangeName @confirm="saveName" ref="rename"></ChangeName>
        <ChangeBillingCycle @confirm="changeBillingCycle" ref="billingcycle"></ChangeBillingCycle>
        <ChangePaymentMethod @confirm="changePaymentMethod" ref="paymentmethod"></ChangePaymentMethod>

    </TabCard>
</template>

<script>
    import ChangeBillingCycle from '@/views/applications/_source/ChangeBillingCycle.vue'
    import AppBtn from '@/views/applications/_source/AppBtn.vue'
    import ChangePaymentMethod from '@/views/applications/_source/ChangePaymentMethod.vue'
    import ChangeName from '@/views/applications/_source/EditText.vue'
    import {memoryFilter} from '@/module/filters/filter'
    import SmallBlock from '@/components/SmallBlock'
    import TabCard from '@/components/TabCard.vue'
    import {mapState,mapGetters} from 'vuex'
    import {formatDate} from '@/module/utils/date'
    export default {
        name: "BaseInfo",
        components: {TabCard ,SmallBlock ,ChangeBillingCycle ,ChangePaymentMethod ,ChangeName ,AppBtn},
        computed: {
            baseInfo() {
                let $this = this
                return [
                    {title: 'Product', content: this.appInfo.product_name  },
                    {title: 'Version', content: this.appInfo.stacks[0].services[0].version},
                    {title: 'Name', content: this.appInfo.name , tip : 'Rename' ,handler(){
                            $this.$refs.rename.open($this.appInfo)
                        }},
                    {title: 'Location', content: this.appInfo.location.region},

                    {title:'Billing Cycle',content:this.appInfo.price.duration_description , tip : 'Change Billing Cycle',handler(){
                            $this.$refs.billingcycle.open($this.appInfo)
                        }},
                    {
                        title: `Configuration (${this.appInfo.plan_name})`,
                        content: `${this.appInfo.cpu} CPU Cores / ${memoryFilter(this.appInfo.memory)} RAM / ${memoryFilter(this.appInfo.disk)} SSD`
                    },
                    {title:'Payment Method',content: `${this.appInfo.payment_method}`, tip : 'Change Payment Method',handler(){
                            $this.$refs.paymentmethod.open()
                        }},
                    {title:'Price',content: `$${this.appInfo.recurring_amount}/${this.billingMonth(this.appInfo.price.duration)}`},
                    {title: 'Created', content: formatDate(this.appInfo.created)},
                    {title: 'Due date', content: formatDate(this.appInfo.next_due_date)},
                ]
            },
            ...mapState('application', ['appInfo']),
            ...mapGetters('application', ['billingMonth']),
        },
        methods: {
            saveName({name}) {
                if (name === this.appInfo.name) {
                    return Promise.resolve()
                }else{
                   return this.$http.put(`/packages/${this.appInfo.uuid}/`, {
                            name: name
                        }).then(() => {
                            this.$store.commit('setState', {
                                    module: 'application',
                                    payload: {
                                        appInfo: Object.assign({}, this.appInfo, { name: name})
                                    }
                                }, { root: true })
                            this.$message.success('Your application was renamed successfully.');
                        }).catch(() => {
                            this.$message.error('Oops, application renaming failed, please contact us at support@cloudclusters.io for this issue.');
                        });
                }
            },
            changeBillingCycle(formData) {
                return this.$http({
                    method: 'put',
                    url: `/packages/${formData.uuid}/billing_cycle/`,
                    data: {
                        price_id: formData.price_id
                    }
                }).then(res => {
                        const payload = Object.assign({}, this.appInfo, {
                            price: res.package.price,
                            invoice: res.package.invoice,
                            recurring_amount: res.package.recurring_amount,
                            invoice_end_date:res.package.invoice_end_date
                        });
                        this.$store.commit('setState', {
                                module: 'application',
                                payload: {
                                    appInfo: payload
                                }
                            }, { root: true }
                        );
                        this.$message.success('The billing cycle was updated successfully!');
                    }).catch(() => {
                        this.$message.error('The billing cycle update failed ! Please try again later.');
                    });
            },
            changePaymentMethod({ payment_method }) {
                return this.$http.put(`/packages/${this.appInfo.uuid}/`, {
                        payment_method: payment_method
                    }).then(res => {
                        const payload = Object.assign({}, this.appInfo, {
                            payment_method: res.payment_method
                        });
                        this.$store.commit('setState', {
                                module: 'application',
                                payload: {
                                    appInfo: payload
                                }
                            }, { root: true }
                        )
                        this.$message.success('The payment method was updated successfully!');
                    }).catch(() => {
                        this.$message.error('The payment method update failed ! Please try again later.');
                    });
            }
        }
    }
</script>

<style lang="scss">
    .base-info-block{
        .info-content{
            height: 40px;
            line-height: 40px;
            background-color: #ebebeb;
            white-space: nowrap;
            position: relative;
            padding: 0 40px 0 10px;
            .info-msg{
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
            .info-ico{
                position: absolute;
                display: inline-block;
                right: 0;
                top: 0;
                width: 40px;
                height: 40px;
                cursor: pointer;
                text-align: center;
            }
        }
    }

</style>