var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "upgrade-modal", attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-form",
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c("v-flex", [
                _c(
                  "p",
                  { staticStyle: { color: "#666", "font-size": "15px" } },
                  [
                    _vm._v(
                      "Please choose your target plan and submit an order first.\n                    There will be about 1-3 minutes of downtime during the upgrade."
                    ),
                    _c("br"),
                    _vm.appInfo.has_paypal_subscription
                      ? _c("span", [
                          _vm._v(
                            "\n                        After you change the plan, the future recurring amount will be changed, followed by your PayPal subscription cancellation.\n                        If you still need automatic payment through PayPal, please click “PayPal Subscription” on your next invoice.\n                    "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _c(
                "v-flex",
                [
                  _c("CurrentPlan"),
                  _c(
                    "SmallBlock",
                    { staticClass: "pt-2" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#333",
                            "font-size": "15px",
                            "font-weight": "bold"
                          },
                          attrs: { slot: "title" },
                          slot: "title"
                        },
                        [_vm._v("Upgrade to")]
                      ),
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: { "min-width": "140px" },
                              attrs: { shrink: "", "py-1": "" }
                            },
                            [_vm._v("New Plan :")]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticStyle: {
                                "min-height": "32px",
                                width: "200px"
                              },
                              attrs: { grow: "", "py-1": "" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  disabled: _vm.selectDisabled,
                                  height: "24",
                                  items: _vm.plans,
                                  "return-object": "",
                                  "item-text": "name",
                                  "hide-details": "",
                                  dense: ""
                                },
                                on: { change: _vm.changePlan },
                                scopedSlots: _vm._u([
                                  {
                                    key: "no-data",
                                    fn: function() {
                                      return [
                                        _c("span", [_vm._v("No upgrade plan")])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.selectedPlan,
                                  callback: function($$v) {
                                    _vm.selectedPlan = $$v
                                  },
                                  expression: "selectedPlan"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: { "min-width": "140px" },
                              attrs: { shrink: "", "py-1": "" }
                            },
                            [_vm._v("Configuration :")]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticStyle: {
                                "min-height": "32px",
                                width: "50%"
                              },
                              attrs: { grow: "", "py-1": "" }
                            },
                            [
                              _vm.selectedPlan.total_cpus
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.selectedPlan.total_cpus) +
                                        " CPU Core(s)"
                                    )
                                  ])
                                : _vm._e(),
                              _vm.selectedPlan.memory_size
                                ? _c("span", [
                                    _vm._v(
                                      " / " +
                                        _vm._s(
                                          parseInt(_vm.selectedPlan.memory_size)
                                        ) +
                                        "GB RAM"
                                    )
                                  ])
                                : _vm._e(),
                              _vm.selectedPlan.disk_size
                                ? _c("span", [
                                    _vm._v(
                                      " / " +
                                        _vm._s(
                                          parseInt(_vm.selectedPlan.disk_size)
                                        ) +
                                        "GB SSD"
                                    )
                                  ])
                                : _vm._e(),
                              _vm.selectedPlan.backup_storage_size
                                ? _c("span", [
                                    _vm._v(
                                      " / " +
                                        _vm._s(
                                          parseInt(
                                            _vm.selectedPlan.backup_storage_size
                                          )
                                        ) +
                                        "GB Backup"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: { "min-width": "140px" },
                              attrs: { shrink: "", "py-1": "" }
                            },
                            [_vm._v("Billing Cycle :")]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticStyle: {
                                "min-height": "32px",
                                width: "200px"
                              },
                              attrs: { grow: "", "py-1": "" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  disabled: _vm.selectDisabled,
                                  height: "24",
                                  items: _vm.currentPrice,
                                  "item-value": "duration",
                                  "return-object": "",
                                  "hide-details": "",
                                  dense: ""
                                },
                                on: { change: _vm.getMigrateFee },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                item.duration_description
                                              ) +
                                              " : $ " +
                                              _vm._s(item.price_base) +
                                              "/month\n                                    "
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                item.duration_description
                                              ) +
                                              " : $ " +
                                              _vm._s(item.price_base) +
                                              "/month\n                                    "
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "no-data",
                                    fn: function() {
                                      return [
                                        _c("span", [
                                          _vm._v("Please select plan first")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.selectedPrice,
                                  callback: function($$v) {
                                    _vm.selectedPrice = $$v
                                  },
                                  expression: "selectedPrice"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: {
                                "min-width": "140px",
                                "min-height": "32px"
                              },
                              attrs: { shrink: "", "py-1": "" }
                            },
                            [_vm._v("Recurring Amount :")]
                          ),
                          _vm.selectedPrice
                            ? _c(
                                "v-flex",
                                {
                                  staticStyle: { "min-height": "32px" },
                                  attrs: { grow: "", "py-1": "" }
                                },
                                [
                                  _vm._v(
                                    "$\n                            " +
                                      _vm._s(
                                        (
                                          _vm.selectedPrice.recurring_amount +
                                          _vm.totalAmount
                                        ).toFixed(2)
                                      ) +
                                      "\n                        "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: {
                                "min-width": "140px",
                                "min-height": "32px"
                              },
                              attrs: { "py-1": "", shrink: "" }
                            },
                            [
                              _vm._v(
                                "\n                            Total Due Today\n                            "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", "nudge-right": "80" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "i",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "fa fa-info-circle",
                                                attrs: { "aria-hidden": "true" }
                                              },
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "The price differential before the app's next due date."
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(
                                "\n                            :\n                        "
                              )
                            ],
                            1
                          ),
                          _vm.migrateFee && !_vm.loading
                            ? _c(
                                "v-flex",
                                {
                                  staticStyle: { "min-height": "32px" },
                                  attrs: { "py-1": "" }
                                },
                                [
                                  _vm._v(
                                    "$ " +
                                      _vm._s(_vm.migrateFee) +
                                      "\n                        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.loading
                            ? _c("v-progress-circular", {
                                staticStyle: { "min-height": "32px" },
                                attrs: { indeterminate: "", width: 3, size: 25 }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }