var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TabCard",
    { staticClass: "base-info-block", attrs: { title: "Overview" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _vm._l(_vm.baseInfo, function(item, i) {
            return _c(
              "v-flex",
              { key: i, attrs: { xs12: "", sm12: "", md6: "" } },
              [
                _c("SmallBlock", [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(item.title))
                  ]),
                  _c("div", { staticClass: "info-content " }, [
                    _c("span", { staticClass: "info-msg" }, [
                      _vm._v(_vm._s(item.content))
                    ]),
                    item.handler
                      ? _c(
                          "span",
                          {
                            staticClass: "info-ico",
                            on: { click: item.handler }
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  size: "20"
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v("fa fa-pencil-square-o")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(item.tip || "change"))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ])
              ],
              1
            )
          }),
          _c(
            "v-flex",
            { staticClass: "pl-0", attrs: { xs12: "" } },
            [_c("AppBtn", { attrs: { app: _vm.appInfo } })],
            1
          )
        ],
        2
      ),
      _c("ChangeName", { ref: "rename", on: { confirm: _vm.saveName } }),
      _c("ChangeBillingCycle", {
        ref: "billingcycle",
        on: { confirm: _vm.changeBillingCycle }
      }),
      _c("ChangePaymentMethod", {
        ref: "paymentmethod",
        on: { confirm: _vm.changePaymentMethod }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }