<template>
    <tab-card title="Reinstallation">
        <p class="fs-3 font-weight-regular" style="color: #666;">
            A  Reinstallation will erase all data of the application. Please make sure you have a backup of your data on your local machine.
        </p>
        <v-btn class="ma-0 text-none" color="error" @click="$refs.install.open()">Install</v-btn>
        <InstallConfirm ref="install" @confirm="installOrRefactory"></InstallConfirm>
    </tab-card>
</template>

<script>


    import InstallConfirm from "./InstallConfirm";
    import TabCard from '@/components/TabCard.vue';
    import { findComponentUpward } from '@/module/utils/assist';
    import  {mapActions} from 'vuex'

    export default {
        name: "index",
        components: {InstallConfirm,TabCard},
        methods:{
            installOrRefactory(formData) {
                return this.$http.post('/factory_settings/factory_restore/',formData).then(() => {
                    this.comp.$on('mask-over',()=>{
                        this.comp.init = false
                        this.getAppDetailById({appId: this.$route.params.appId,isReset:true}).then(()=>{
                            this.getAppStats().then(()=>{
                                this.comp.init = true
                            })
                        })

                    });
                    if (!this.comp.showLoading) {
                        this.comp.tick();
                        return Promise.resolve()
                    }
                }).catch(e => {
                    this.$message.error(e.detail);
                });
            },
            ...mapActions('application',['getAppDetailById','getAppStats'])
        },
        created() {
            this.comp = findComponentUpward(this, 'ApplicationDetail');
        },
        beforeDestroy() {
            this.comp.$off('mask-over');
        }
    }
</script>

<style lang="scss">

</style>