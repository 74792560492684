<template>
    <v-container class="panel-wrapper panel-overview pa-0" grid-list-lg fluid >
        <v-layout column>
            <v-flex v-if="showConnection">
                <MysqlConnectionInfo ></MysqlConnectionInfo>
            </v-flex>
            <v-flex>
                <BaseInfo></BaseInfo>
            </v-flex>
            <v-flex v-if="showOplogUser">
                <OplogUser></OplogUser>
            </v-flex>
            <v-flex>
                <RealStatus></RealStatus>
            </v-flex>
            <v-flex>
                <Scaling></Scaling>
            </v-flex>
            <v-flex v-if="appInfo.product_name.toLowerCase()!=='cockroachdb'">
                <Reinstall></Reinstall>
            </v-flex>
            <v-flex>
                <Cancellation></Cancellation>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'

    import BaseInfo from './_source/baseinfo'
    // import ConnectionInfo from './_source/connectionInfo'
    import MysqlConnectionInfo from './_source/mysqlConnectionInfo'
    import Cancellation from './_source/cancellation/index'
    import RealStatus from './_source/realStatus'
    import OplogUser from './_source/oplogUser/OplogUser.vue'
    import Scaling from './_source/scaling/Scaling.vue'
    import Reinstall from './_source/Reinstall/index.vue'

    export default {
        name: "WpOverview",
        components: { BaseInfo, Cancellation , RealStatus,OplogUser,Scaling ,Reinstall,MysqlConnectionInfo},
        computed: {
            showOplogUser() {
                return this.appInfo.product_name === 'MongoDB';
            },
            showConnection(){
                return this.appInfo.category === 'Database' || this.appInfo.product_name.toLowerCase() === 'kafka'
            },
            ...mapState('application', ['appInfo']),
        },
    }
</script>

<style lang="scss">
    .panel-overview {
        background-color: transparent;
    }
</style>
