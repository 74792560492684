var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "installation",
      attrs: { persistent: "", "max-width": "600px", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm.dialog
        ? _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Reinstallation")
                    ])
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "justify-space-between": "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                sm6: "",
                                md6: "",
                                "pb-3": "",
                                "pr-2": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "install-select ",
                                  class: { active: !_vm.show },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeType("Refactory")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            Reinstall with Current Version\n                        "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm.appInfo.product_name.toLowerCase() !== "magento"
                            ? _c(
                                "v-flex",
                                {
                                  attrs: {
                                    sm6: "",
                                    md6: "",
                                    "pb-3": "",
                                    "pl-2": ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "install-select ",
                                      class: { active: _vm.show },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeType("Reinstall")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Reinstall with a Different Version\n                        "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                sm12: "",
                                md12: "",
                                lg12: "",
                                "pb-3": ""
                              }
                            },
                            [
                              _c(
                                "Expend",
                                {
                                  model: {
                                    value: _vm.show,
                                    callback: function($$v) {
                                      _vm.show = $$v
                                    },
                                    expression: "show"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      slot: "content",
                                      "item-text": "number",
                                      "item-value": "number",
                                      disabled: _vm.versionsLoading,
                                      rules: [_vm.rules.required],
                                      items: _vm.currentVersions
                                    },
                                    slot: "content",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c("RequiredDot", [
                                                _vm._v("Version")
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      136281664
                                    ),
                                    model: {
                                      value: _vm.version,
                                      callback: function($$v) {
                                        _vm.version = $$v
                                      },
                                      expression: "version"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "Expend",
                                {
                                  model: {
                                    value: _vm.images.length,
                                    callback: function($$v) {
                                      _vm.$set(_vm.images, "length", $$v)
                                    },
                                    expression: "images.length"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      slot: "content",
                                      "item-value": "id",
                                      disabled: _vm.versionsLoading,
                                      rules: [_vm.rules.required],
                                      items: _vm.images
                                    },
                                    slot: "content",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c("RequiredDot", [
                                                _vm._v("Components")
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.componentText(
                                                      item.components
                                                    )
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.componentText(
                                                      item.components
                                                    )
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2668674899
                                    ),
                                    model: {
                                      value: _vm.formData.image_id,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "image_id", $$v)
                                      },
                                      expression: "formData.image_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-flex", { attrs: { sm12: "", md12: "" } }, [
                            _c(
                              "p",
                              { style: { color: _vm.$vuetify.theme.error } },
                              [
                                _vm._v(
                                  "The function will clear all data of your\n                            application and cannot be undone. Please take a backup to your local in case you need\n                            them in future."
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: { color: "blue darken-1", flat: "" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: { color: "primary", loading: _vm.loading },
                          on: { click: _vm.ok }
                        },
                        [_vm._v("Confirm")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }