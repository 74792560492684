<template>
    <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
        <v-form v-model="valid" ref="form" lazy-validation v-if="dialog">
            <v-card>
                <v-card-title>
                    <span class="title">New Oplog User</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container fluid pa-0 grid-list-lg>
                        <v-text-field
                                v-model="formData.name"
                                :rules="[rules.required]"
                                :height="30"
                                required
                                clearable>
                            <template v-slot:label>
                                <RequiredDot>Name</RequiredDot>
                            </template>
                        </v-text-field>
                        <v-layout wrap>
                            <v-flex xs6 >
                                <v-text-field
                                        v-model="formData.password"
                                        :rules="[rules.required]"
                                        type="password"
                                        persistent-hint
                                >
                                    <template v-slot:label>
                                        <RequiredDot>Password</RequiredDot>
                                    </template>
                                </v-text-field>
                            </v-flex>
                            <v-flex xs6 >
                                <v-text-field
                                        v-model="formData.passwordConfirm"
                                        :rules="[rules.required ,rules.same]"
                                        type="password">
                                    <template v-slot:label>
                                        <RequiredDot>Confirm Password</RequiredDot>
                                    </template>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat @click="close">Close</v-btn>
                    <v-btn color="primary" @click="ok" :loading="loading">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import RequiredDot from '@/components/RequiredDot.vue'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import rules from '@/module/rules/index.js'
    import {mapState } from 'vuex' //mapState  mapGetters mapActions mapGetters


    export default {
        name: "AddOUser",
        mixins: [loadingMixin],
        components: {RequiredDot},
        data() {
            return {
                rules: {
                    same: value => value===this.formData.password||'Passwords do not match.',
                    ...rules,
                },
                formData: {
                    name: '',
                    password: '',
                    passwordConfirm: ''
                }
            }
        },
        computed: {
            ...mapState('application', [ 'appInfo'])
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.$refs.form.reset()
                    this.formData =  {
                        name: '',
                        password: '',
                        passwordConfirm: ''
                    }
                }
            }
        },
        methods: {
            $validator(){
                return this.$refs.form.validate()
            }
        }
    }
</script>

<style lang="scss">

</style>
