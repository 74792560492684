<template>
    <v-container pa-0>
        <v-form>
            <v-layout column>
                <v-flex>
                    <p style="color: #666;font-size: 14px;">
                        Please choose the add-on items and set the amount you'd like to remove.
                        Then click the submit button. There will be about 1-3 minutes of downtime during the downgrade.<br>
                        <span v-if="appInfo.has_paypal_subscription">
                            After you update add-on configurations, the future recurring amount will be changed, followed by your PayPal subscription cancellation.
                            If you still need automatic payment through PayPal, please click “PayPal Subscription” on your next invoice.
                        </span></p>
                </v-flex>
                <v-flex>
                    <CurrentPlan></CurrentPlan>
                    <SmallBlock class="pt-2">
                        <span slot="title" style="color:#333;font-size: 15px;font-weight: bold;">Add-on Items</span>
                        <v-layout v-for="(v,k) in removeOns" v-show="k!=='dedicated_ip'" style="height: 50px" :key="k" row>
                            <v-flex grow style="width: 300px;">
                                <v-checkbox v-model="v.flag" :disabled="!v.value.length" height="24">
                                    <template v-slot:label><span style="font-size: 15px">{{v.description}}</span>
                                    </template>
                                </v-checkbox>
                            </v-flex>
                            <v-flex shrink ml-2 style="min-height: 32px;">
                                <v-autocomplete v-model="v.selected"  height="24" :disabled="!v.flag"
                                                hide-details dense :items="v.value">
                                    <template v-slot:item="{item}">
                                        <span>
                                            {{removeonsUnit(item,k)}}
                                        </span>
                                    </template>
                                    <template v-slot:selection="{item}">
                                        <span>
                                            {{removeonsUnit(item,k)}}
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </v-flex>
                        </v-layout>
                        <v-layout row mt-3>
                            <v-flex shrink style="min-width: 140px;min-height: 32px;" py-1 >Recurring Amount :</v-flex>
                            <v-flex v-if="addonsRecurringAmount&&!loading" grow py-1 style="min-height: 32px;">$ {{addonsRecurringAmount}}</v-flex>
                            <v-progress-circular v-if="loading" style="min-height: 32px;" indeterminate :width="3"
                                                 :size="25"></v-progress-circular>
                        </v-layout>
                    </SmallBlock>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
    import SmallBlock from '@/components/SmallBlock'
    import CurrentPlan from '../_source/CurrentPlan'
    import {mapState} from 'vuex'

    export default {
        name: "RemovePlan",
        components: {
            SmallBlock,
            CurrentPlan
        },
        data() {
            return {
                removeOns: {
                    cpu: {
                        selected: 0,
                        flag: false,
                        value: []
                    },
                    memory: {
                        selected: 0,
                        flag: false,
                        value: []
                    },
                    disk: {
                        selected: 0,
                        flag: false,
                        value: []
                    },
                    backup_space: {
                        selected: 0,
                        flag: false,
                        value: []
                    },
                    dedicated_ip: {
                        selected: 0,
                        flag: false,
                        value: []
                    }
                },
                addonsRecurringAmount: '',
                loading: false,

            }
        },
        computed: {
            removeonsUnit() {
                return (item, k) => {
                    switch (k) {
                        case 'cpu':
                            return item + ' Core(s)'
                        case 'memory':
                            return item + ' GB'
                        case 'disk':
                            return item + '*20GB'
                        case 'backup_space':
                            return item + '*100GB'
                        case 'dedicated_ip':
                            return item
                    }
                }
            },
            ...mapState('application', ['appInfo']),
        },
        watch: {
            removeOns: {
                deep: true,
                handler() {
                    this.getAddonsRecurringAmount()
                }
            }
        },
        methods: {
            getAlreadyAddons() {
                this.$http.get(`/packages/${this.appInfo.uuid}/get_pre_remove_addon_conditions/`).then(res => {
                    for (let item in res) {
                        res[item] = Object.assign(res[item], {
                            selected: 0,
                            flag: false
                        })
                    }
                    this.removeOns = res
                }).catch(err => {
                    this.$message.error(err.detail)
                })
            },
            getAddonsRecurringAmount() {
                const formData = this.getFormData()
                if (!formData) {
                    this.addonsRecurringAmount = this.appInfo.recurring_amount
                    return
                }
                this.loading = true
                this.$http.get(`/packages/${this.appInfo.uuid}/get_remove_addon_recurring_amount/`,{
                    params: this.getFormData()
                }).then(res => {
                    this.loading = false
                    this.addonsRecurringAmount = res.remove_addon_recurring_amount
                }).catch(err => {
                    this.loading = false
                    this.$message.error(err.detail)
                })
            },
            getFormData() {
                const formData = {
                    cpu: this.removeOns.cpu.flag ? this.removeOns.cpu.selected : 0,
                    memory: this.removeOns.memory.flag ? this.removeOns.memory.selected : 0,
                    disk: this.removeOns.disk.flag ? this.removeOns.disk.selected : 0,
                    backup_space: this.removeOns.backup_space.flag ? this.removeOns.backup_space.selected : 0,
                    dedicated_ip: this.removeOns.dedicated_ip.flag ? this.removeOns.dedicated_ip.selected : 0,
                    cpu_unit_price_id: this.removeOns.cpu.id,
                    memory_unit_price_id: this.removeOns.memory.id,
                    disk_unit_price_id: this.removeOns.disk.id,
                    backup_space_unit_price_id: this.removeOns.backup_space.id,
                    dedicated_ip_unit_price_id: this.removeOns.dedicated_ip.id
                }
                if (!formData.cpu && !formData.memory && !formData.backup_space && !formData.dedicated_ip && !formData.disk) {
                    return false
                } else {
                    return formData
                }
            }
        },
        created() {
            this.getAlreadyAddons()
        }
    }
</script>

<style lang="scss">

</style>
