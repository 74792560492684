<template>
    <TabCard class="mysql-cnt" title="Connection Information">
        <v-layout column v-if="!loading">
            <template v-for="(group, gid) in deploymentInfo.connection_info">
                <v-flex :key="gid" v-url v-if="group.description">
                    <p style="font-size: 14px;color: #666;margin: 0;" v-html="group.description">
                    </p>
                </v-flex>
                <v-flex py-3 :key="gid">
                    <v-layout wrap>
                        <v-flex xs12 v-for="(item, idx ) in group.cmds" class="cnt-wrapper" :key="idx">
                            <template v-if="item.type==='shell'">
                                <SmallBlock :title="item.name">
                                    <kbd v-if="item.value"
                                         style="min-width: 100%;word-break: break-all;">{{item.value}}</kbd>
                                </SmallBlock>
                            </template>
                            <template v-else-if="item.actions">
                                <v-layout mx-0 class="font-weight-regular fs-4 border-bottom"
                                          style="background-color: #F7F7F7;color: #000000;padding: 5px 0;">
                                    <v-flex ml-3 xs4 sm3 md3 class="font-weight-bold" style="color: #444">
                                        {{item.name}}
                                    </v-flex>
                                    <v-flex xs4 sm6 md6 v-if="item.type!=='password'" v-http="item.value">
                                    </v-flex>
                                    <v-flex xs4 sm6 md6 v-else>
                                        <input :id="item.name.replace(' ','')" type="password" readonly
                                               style="max-width: 120px"
                                               :value="item.value"/>
                                        <v-icon style="position: relative;top: 2px;" @click="showOrHide(item)">
                                            {{item.pwd_icon||'visibility_off'}}
                                        </v-icon>
                                    </v-flex>
                                    <v-flex xs4 sm3 md3>
                                        <a v-for="action in item.actions" :key="action.name"
                                           @click="actionHandle(action,item)">{{action.name}}&nbsp;
                                            &nbsp;</a>
                                    </v-flex>
                                </v-layout>
                            </template>
                            <template v-else>
                                <SmallBlock :title="item.name">
                                    <div class="kb-content font-weight-regular" v-html="item.value"></div>
                                </SmallBlock>
                            </template>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </template>
        </v-layout>
        <template v-else>
            <fy-vue-skeleton
                    animation="fade"
                    :rowOptions="{padding : '10px'}"
                    :childOptions="skeletonOptions"
            ></fy-vue-skeleton>
        </template>
    </TabCard>
</template>

<script>
    import SmallBlock from '@/components/SmallBlock'
    import TabCard from '@/components/TabCard.vue'
    import {download} from "@/module/utils/download";
    import {mapActions, mapState} from "vuex";


    const delay = (function () {
        let timer = 0
        return function (callback, ms) {
            clearTimeout(timer)
            timer = setTimeout(callback, ms)
        }
    })()

    const skeletonOptions = [
        {
            span: 24,
            type: 'list',
            options: {repeat: 6, list: [{height: '30px', width: '100%', marginTop: '10px'}]}
        },
        {
            type: "card",
            span: '24',
            options: {
                height: "25px",
                width: '140px',
                marginTop: '20px'
            }
        },
        {span: 24, type: 'card', options: {height: '50px', width: '100%', marginTop: '20px'}},
        {
            type: "card",
            span: '24',
            options: {
                height: "25px",
                width: '140px',
                marginTop: '20px'
            }
        },
        {
            span: 24,
            type: 'list',
            options: {repeat: 2, list: [{height: '20px', width: '50%'}], marginTop: '30px'}
        },
    ]

    export default {
        name: "ConnectionInfo",
        components: {TabCard, SmallBlock},
        computed: {
            ...mapState('application', ['deploymentInfo']),
        },
        data() {
            return {
                pwd_icon: 'visibility_off',
                loading: true,
            }
        },
        methods: {
            actionHandle(action, item) {
                delay(()=>{
                    switch (action.name) {
                        case 'Copy':
                            this.copyShareText(item.value)
                            break
                        // case 'Show/Hide':
                        //     this.showOrHide(item.name)
                        //     break
                        case 'Download' :
                            download(action.url)
                            break
                    }
                },500)

            },
            copyShareText(shareText) {
                let input = document.createElement("input")
                input.value = shareText
                document.body.appendChild(input)
                input.select()
                document.execCommand("Copy")
                this.$message.success('Copy successfully')
                document.body.removeChild(input)
            },
            showOrHide(item) {
                let input = document.getElementById(item.name.replace(' ', ''))
                if (input.type === "password") {
                    this.$set(item, 'pwd_icon', 'visibility')
                    input.type = 'text'
                } else {
                    this.$set(item, 'pwd_icon', 'visibility_off')
                    input.type = 'password'
                }
            },
            ...mapActions('application', ['getConnectionInfo'])
        }
        ,
        created() {
            this.skeletonOptions = skeletonOptions
            this.getConnectionInfo().then(() => {
                this.loading = false
            })

        }
    }
</script>

<style lang="scss">
    .mysql-cnt {
        .kb-content {
            a {
                display: inline-block;
                font-size: 14px;
                color: #495AB9;
                padding: 5px 0;
            }
        }

        .cnt-wrapper:last-child {
            .border-bottom {
                border: 0;
            }
        }

        .border-bottom {
            border-bottom: 1px solid #D9D9D9;
            /*&:last-child {*/
            /*    border:0;*/
            /*}*/
        }
    }

</style>