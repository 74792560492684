var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TabCard",
    { staticClass: "real-status-block", attrs: { title: "Real-Time Status" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c("v-flex", [
            _c(
              "p",
              {
                staticClass: "fs-3 font-weight-regular",
                staticStyle: { "font-size": "14px", color: "#666", margin: "0" }
              },
              [
                _vm._v(
                  "Each instance has\n                    dedicated resources. The\n                    following shows each instance's status and resource usage."
                )
              ]
            )
          ]),
          _c(
            "v-flex",
            [
              _c("v-data-table", {
                staticClass: "elevation-0 source-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.instances,
                  "hide-actions": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("td", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.name) +
                              "\n                        "
                          )
                        ]),
                        item.type
                          ? _c("td", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.type) +
                                  "\n                        "
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "td",
                          [
                            _c(
                              "v-layout",
                              { attrs: { column: "" } },
                              [
                                _c("v-flex", { staticClass: "pb-0 pt-4" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.memoryString(
                                          item.used_memory,
                                          item.total_memory,
                                          true
                                        )
                                      ) +
                                      "\n                                "
                                  )
                                ]),
                                _c(
                                  "v-flex",
                                  { staticClass: "pt-0" },
                                  [
                                    _c("v-progress-linear", {
                                      staticClass: "elevation-2",
                                      attrs: {
                                        value: Math.round(
                                          (item.used_memory /
                                            1024 /
                                            item.total_memory) *
                                            100
                                        ),
                                        height: "5",
                                        color: "blue"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "v-layout",
                              { attrs: { column: "" } },
                              [
                                _c("v-flex", { staticClass: "pb-0 pt-4" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.memoryString(
                                          item.used_disk,
                                          item.total_disk
                                        )
                                      ) +
                                      "\n                                "
                                  )
                                ]),
                                _c(
                                  "v-flex",
                                  { staticClass: "pt-0" },
                                  [
                                    _c("v-progress-linear", {
                                      staticClass: "elevation-2",
                                      attrs: {
                                        value: Math.round(
                                          (item.used_disk / item.total_disk) *
                                            100
                                        ),
                                        height: "5",
                                        color: "blue"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("td", [
                          item.status === "Running"
                            ? _c(
                                "span",
                                { staticStyle: { color: "#028e01" } },
                                [_vm._v("Running")]
                              )
                            : _vm._e(),
                          item.status === "Startup"
                            ? _c(
                                "span",
                                { staticStyle: { color: "#ff5a01" } },
                                [_vm._v("Startup")]
                              )
                            : _vm._e(),
                          item.status === "Failed"
                            ? _c(
                                "span",
                                { staticStyle: { color: "#8e0000" } },
                                [_vm._v("Failed")]
                              )
                            : _vm._e()
                        ])
                      ]
                    }
                  },
                  {
                    key: "no-data",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "min-height": "192px",
                              position: "relative"
                            }
                          },
                          [_c("Empty")],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-flex",
            [
              _c("v-subheader", { staticClass: "panel-title" }, [
                _vm._v("Backup Disk Space")
              ]),
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm5: "" } }, [
                    _c(
                      "p",
                      {
                        staticClass: "fs-3 font-weight-regular",
                        staticStyle: { color: "#666", margin: "0" }
                      },
                      [
                        _vm._v(
                          "Dedicated for backups of this\n                            deployment"
                        )
                      ]
                    )
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm7: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "fs-3 font-weight-bold",
                              staticStyle: { "align-self": "center" },
                              attrs: { shrink: "" }
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.memoryString(
                                      _vm.runningStatus.used_backup_disk,
                                      _vm.runningStatus.total_backup_disk
                                    )
                                  ) +
                                  "\n                            "
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { grow: "" } },
                            [
                              !!_vm.runningStatus.total_backup_disk
                                ? _c(
                                    "v-progress-circular",
                                    {
                                      attrs: {
                                        rotate: -90,
                                        size: 100,
                                        width: 15,
                                        value:
                                          (_vm.runningStatus.used_backup_disk /
                                            _vm.runningStatus
                                              .total_backup_disk) *
                                          100,
                                        color: "light-blue"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.value) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("AppCheck")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }