var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TabCard",
    { staticClass: "mysql-cnt", attrs: { title: "Connection Information" } },
    [
      !_vm.loading
        ? _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _vm._l(_vm.deploymentInfo.connection_info, function(group, gid) {
                return [
                  group.description
                    ? _c(
                        "v-flex",
                        {
                          directives: [{ name: "url", rawName: "v-url" }],
                          key: gid
                        },
                        [
                          _c("p", {
                            staticStyle: {
                              "font-size": "14px",
                              color: "#666",
                              margin: "0"
                            },
                            domProps: { innerHTML: _vm._s(group.description) }
                          })
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { key: gid, attrs: { "py-3": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        _vm._l(group.cmds, function(item, idx) {
                          return _c(
                            "v-flex",
                            {
                              key: idx,
                              staticClass: "cnt-wrapper",
                              attrs: { xs12: "" }
                            },
                            [
                              item.type === "shell"
                                ? [
                                    _c(
                                      "SmallBlock",
                                      { attrs: { title: item.name } },
                                      [
                                        item.value
                                          ? _c(
                                              "kbd",
                                              {
                                                staticStyle: {
                                                  "min-width": "100%",
                                                  "word-break": "break-all"
                                                }
                                              },
                                              [_vm._v(_vm._s(item.value))]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                : item.actions
                                ? [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass:
                                          "font-weight-regular fs-4 border-bottom",
                                        staticStyle: {
                                          "background-color": "#F7F7F7",
                                          color: "#000000",
                                          padding: "5px 0"
                                        },
                                        attrs: { "mx-0": "" }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "font-weight-bold",
                                            staticStyle: { color: "#444" },
                                            attrs: {
                                              "ml-3": "",
                                              xs4: "",
                                              sm3: "",
                                              md3: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(item.name) +
                                                "\n                                "
                                            )
                                          ]
                                        ),
                                        item.type !== "password"
                                          ? _c("v-flex", {
                                              directives: [
                                                {
                                                  name: "http",
                                                  rawName: "v-http",
                                                  value: item.value,
                                                  expression: "item.value"
                                                }
                                              ],
                                              attrs: {
                                                xs4: "",
                                                sm6: "",
                                                md6: ""
                                              }
                                            })
                                          : _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs4: "",
                                                  sm6: "",
                                                  md6: ""
                                                }
                                              },
                                              [
                                                _c("input", {
                                                  staticStyle: {
                                                    "max-width": "120px"
                                                  },
                                                  attrs: {
                                                    id: item.name.replace(
                                                      " ",
                                                      ""
                                                    ),
                                                    type: "password",
                                                    readonly: ""
                                                  },
                                                  domProps: {
                                                    value: item.value
                                                  }
                                                }),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      position: "relative",
                                                      top: "2px"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showOrHide(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          item.pwd_icon ||
                                                            "visibility_off"
                                                        ) +
                                                        "\n                                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: { xs4: "", sm3: "", md3: "" }
                                          },
                                          _vm._l(item.actions, function(
                                            action
                                          ) {
                                            return _c(
                                              "a",
                                              {
                                                key: action.name,
                                                on: {
                                                  click: function($event) {
                                                    return _vm.actionHandle(
                                                      action,
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(action.name) +
                                                    " \n                                         "
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : [
                                    _c(
                                      "SmallBlock",
                                      { attrs: { title: item.name } },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "kb-content font-weight-regular",
                                          domProps: {
                                            innerHTML: _vm._s(item.value)
                                          }
                                        })
                                      ]
                                    )
                                  ]
                            ],
                            2
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          )
        : [
            _c("fy-vue-skeleton", {
              attrs: {
                animation: "fade",
                rowOptions: { padding: "10px" },
                childOptions: _vm.skeletonOptions
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }