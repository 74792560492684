<template>
    <div>
        <slot name="header" :on="clickEvent()">
        </slot>
        <transition
                v-on="transitionHooks()">
            <div v-show="show" class="trans-wrapper">
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "Expend",
        data() {
            return {
                show: this.value
            }
        },
        props: {
            value: Boolean
        },
        watch: {
            value(v){
                this.show = v
            },
            show() {
                this.$emit('input',this.show)
            }
        },
        methods: {
            clickEvent() {
                const _this = this
                return {
                    click: function () {
                        _this.show = !_this.show
                    }
                }
            },
            transitionHooks() {
                const elTransition = '0.3s height ease-in-out, 0.3s padding-top ease-in-out, 0.3s padding-bottom ease-in-out'
                return {
                    'before-enter' (el) {
                        el.style.transition = elTransition
                        if (!el.dataset) el.dataset = {}

                        el.dataset.oldPaddingTop = el.style.paddingTop
                        el.dataset.oldPaddingBottom = el.style.paddingBottom

                        el.style.height = 0
                        el.style.paddingTop = 0
                        el.style.paddingBottom = 0

                    },

                    'enter' (el) {
                        el.dataset.oldOverflow = el.style.overflow
                        if (el.scrollHeight !== 0) {
                            el.style.height = el.scrollHeight + 'px'
                            el.style.paddingTop = el.dataset.oldPaddingTop
                            el.style.paddingBottom = el.dataset.oldPaddingBottom
                        } else {
                            el.style.height = ''
                            el.style.paddingTop = el.dataset.oldPaddingTop
                            el.style.paddingBottom = el.dataset.oldPaddingBottom
                        }

                        el.style.overflow = 'hidden'
                    },

                    'after-enter' (el) {
                        el.style.transition = ''
                        el.style.height = ''
                        el.style.overflow = el.dataset.oldOverflow
                    },

                    'before-leave' (el) {
                        if (!el.dataset) el.dataset = {}
                        el.dataset.oldPaddingTop = el.style.paddingTop
                        el.dataset.oldPaddingBottom = el.style.paddingBottom
                        el.dataset.oldOverflow = el.style.overflow

                        el.style.height = el.scrollHeight + 'px'
                        el.style.overflow = 'hidden'
                    },

                    'leave' (el) {
                        if (el.scrollHeight !== 0) {
                            el.style.transition = elTransition
                            el.style.height = 0
                            el.style.paddingTop = 0
                            el.style.paddingBottom = 0
                        }
                    },

                    'after-leave' (el) {
                        el.style.transition = ''
                        el.style.height = ''
                        el.style.overflow = el.dataset.oldOverflow
                        el.style.paddingTop = el.dataset.oldPaddingTop
                        el.style.paddingBottom = el.dataset.oldPaddingBottom
                    }
                }
            },
        }
    }
</script>
