<template>
    <v-dialog class="installation" v-model="dialog" persistent max-width="600px" no-click-animation>
        <v-form v-model="valid" ref="form" lazy-validation v-if="dialog">
            <v-card>
                <v-card-title>
                    <span class="title">Reinstallation</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-layout wrap justify-space-between>
                        <v-flex sm6 md6 pb-3 pr-2>
                            <div class="install-select " :class="{'active':!show}" @click="changeType('Refactory')">
                                Reinstall with Current Version
                            </div>
                        </v-flex>
                        <v-flex sm6 md6 pb-3 pl-2 v-if="appInfo.product_name.toLowerCase()!=='magento'">
                            <div class="install-select " :class="{'active':show}"
                                 @click="changeType('Reinstall')">
                                Reinstall with a Different Version
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout column>
                        <v-flex sm12 md12 lg12 pb-3>
                            <Expend v-model="show">
                                <v-select
                                        slot="content"
                                        item-text="number"
                                        item-value="number"
                                        v-model="version"
                                        :disabled="versionsLoading"
                                        :rules="[rules.required]"
                                        :items="currentVersions">
                                    <template v-slot:label>
                                        <RequiredDot>Version</RequiredDot>
                                    </template>
                                </v-select>
                            </Expend>
                            <Expend v-model="images.length">
                                <v-select
                                        slot="content"
                                        item-value="id"
                                        v-model="formData.image_id"
                                        :disabled="versionsLoading"
                                        :rules="[rules.required]"
                                        :items="images">
                                    <template v-slot:label>
                                        <RequiredDot>Components</RequiredDot>
                                    </template>
                                    <template v-slot:selection="{item}">
                                        {{componentText(item.components)}}
                                    </template>
                                    <template v-slot:item="{item}">
                                        {{componentText(item.components)}}
                                    </template>
                                </v-select>
                            </Expend>
                        </v-flex>
                        <v-flex sm12 md12>
                            <p :style="{'color': $vuetify.theme.error}">The function will clear all data of your
                                application and cannot be undone. Please take a backup to your local in case you need
                                them in future.</p>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" color="blue darken-1" flat @click="close">Close</v-btn>
                    <v-btn class="text-none" color="primary" :loading="loading" @click="ok">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    // import RequiredDot from '@/components/RequiredDot.vue'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import rules from '@/module/rules/index.js'
    import {mapState} from 'vuex'
    import Expend from "@/components/Expend"; //mapState  mapGetters mapActions mapGetters

    export default {
        name: "InstallConfirm",
        components: {Expend},
        mixins: [loadingMixin],
        // components: {RequiredDot},
        data() {
            return {
                versions: [],
                versionsLoading: false,
                rules: {
                    ...rules,
                    same: value => value === this.appInfo.name || "The entered name doesn't match ",
                },
                version: '',
                images:[],
                type: 'Refactory',
                formData: {
                    image_id: '',
                    service_uuid: ''
                },
            }
        },
        computed: {
            componentText() {
                return (components)=>{
                    if (!components.length) {
                        return ''
                    }else {
                        return components.map( o => `${o.name}@${o.version}`).join('__')
                    }
                }
            },
            show() {
                return this.type === 'Reinstall'
            },
            currentVersions() {
                const v = this.versions.filter((item) => {
                    const flag = item.number === this.appInfo.version
                    return this.show ? !flag : flag
                })
                return v
            },
            // currentImages() {
            //     let images = null
            //     this.currentVersions.forEach((item) => {
            //         if (item.number == this.version) {
            //             images = item.images
            //         }
            //     })
            //     if(images === null) {
            //         return []
            //     }else {
            //         return images
            //     }
            // },
            ...mapState('application', ['appInfo'])
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.$refs.form.reset()
                    this.type = 'Refactory'
                    this.formData = {
                        image_id: '',
                        service_uuid: ''
                    }
                } else {
                    this.version = this.appInfo.version
                    this.formData.image_id = this.appInfo.jcy_image
                    this.formData.service_uuid = this.appInfo.stacks[0].services[0].uuid
                }
            },
            version(val) {
                let ver = this.versions.find(o => o.number === val)
                if (ver) {
                    this.images = ver.images
                    if (this.images.length === 1) {
                        this.formData.image_id = this.images[0].id
                    } else {
                        this.formData.image_id = ''
                    }
                } else {
                    this.images = []
                }
            }
        },
        methods: {
            $validator() {
                return this.$refs.form.validate() || this.formData.image_id
            },
            changeType(type) {
                if (type === 'Refactory') {
                    this.version = this.appInfo.version
                    this.formData.image_id = this.appInfo.jcy_image
                    this.type = type
                } else {
                    if (this.versions.filter((item) => item.number !== this.appInfo.version).length) {
                        this.version = ''
                        this.formData.image_id = ''
                        this.type = type
                    } else {
                        this.$message.info('Currently, there are no other supported versions.')
                    }
                }

            },
            getVersions() {
                this.versionsLoading = true
                this.$http.get(`/products/${this.appInfo.product_name}/versions/`).then((res) => {
                    this.versions = res.versions
                    this.versionsLoading = false
                }).catch((e) => {
                    this.versionsLoading = false
                    this.$message.error(e.detail)
                })
            },
        },
        created() {
            this.getVersions()
        }
    }
</script>

<style lang="scss">
    .install-select {
        border-radius: 5px;
        border: 2px solid #eeeeee;
        height: 40px;
        line-height: 37px;
        cursor: pointer;
        color: #0f0f0f;
        text-align: center;

        &.active {
            background-color: #DAE0FF;
            color: #3F51B5;
        }
    }

    /*.version-select {*/
    /*    height: 0px;*/
    /*    opacity: 0;*/
    /*    transition: height ease .3s;*/
    /*    &.active {*/
    /*        height: 90px;*/
    /*        opacity: 1;*/
    /*    }*/
    /*}*/
</style>
