var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SmallBlock",
    [
      _c(
        "span",
        {
          staticStyle: {
            color: "#333",
            "font-size": "15px",
            "font-weight": "bold"
          },
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("Current Plan Details")]
      ),
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c("v-flex", {
            staticStyle: { "min-width": "140px" },
            attrs: { shrink: "", "py-1": "" },
            domProps: { textContent: _vm._s(_vm.appInfo.plan_name + " Plan :") }
          }),
          _c("v-flex", {
            staticStyle: { "min-height": "32px", width: "50%" },
            attrs: { grow: "", "py-1": "" },
            domProps: {
              textContent: _vm._s(
                parseInt(_vm.appInfo.plan.total_cpus) +
                  " CPU Core(s) / " +
                  parseInt(_vm.appInfo.plan.memory_size) +
                  "GB RAM / " +
                  parseInt(_vm.appInfo.plan.disk_size) +
                  "GB SSD / " +
                  parseInt(_vm.appInfo.plan.backup_storage_size) +
                  "GB Backup"
              )
            }
          })
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c(
            "v-flex",
            {
              staticStyle: { "min-width": "140px" },
              attrs: { shrink: "", "py-1": "" }
            },
            [_vm._v("Current Add-ons :")]
          ),
          _c(
            "v-flex",
            {
              staticStyle: { "min-height": "32px", width: "50%" },
              attrs: { grow: "", "py-1": "" }
            },
            [
              _vm.alreadyAddons.cpu
                ? _c("span", [
                    _vm._v(_vm._s(_vm.alreadyAddons.cpu) + " CPU Core(s) ")
                  ])
                : _vm._e(),
              _vm.alreadyAddons.memory
                ? _c("span", [
                    _vm.alreadyAddons.cpu
                      ? _c("i", { staticStyle: { "font-style": "normal" } }, [
                          _vm._v("/")
                        ])
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.alreadyAddons.memory) + "GB RAM ")
                  ])
                : _vm._e(),
              _vm.alreadyAddons.disk
                ? _c("span", [
                    _vm.alreadyAddons.memory || _vm.alreadyAddons.cpu
                      ? _c("i", { staticStyle: { "font-style": "normal" } }, [
                          _vm._v("/")
                        ])
                      : _vm._e(),
                    _vm._v(
                      " " + _vm._s(_vm.alreadyAddons.disk * 20) + "GB SSD "
                    )
                  ])
                : _vm._e(),
              _vm.alreadyAddons.backup_space
                ? _c("span", [
                    _vm.alreadyAddons.memory ||
                    _vm.alreadyAddons.cpu ||
                    _vm.alreadyAddons.disk
                      ? _c("i", { staticStyle: { "font-style": "normal" } }, [
                          _vm._v("/")
                        ])
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(_vm.alreadyAddons.backup_space * 100) +
                        "GB Backup "
                    )
                  ])
                : _vm._e(),
              _vm.alreadyAddons.dedicated_ip
                ? _c("span", [
                    _vm.alreadyAddons.backup_space ||
                    _vm.alreadyAddons.memory ||
                    _vm.alreadyAddons.cpu ||
                    _vm.alreadyAddons.disk
                      ? _c("i", { staticStyle: { "font-style": "normal" } }, [
                          _vm._v("/")
                        ])
                      : _vm._e(),
                    _vm._v(
                      " " + _vm._s(_vm.alreadyAddons.dedicated_ip) + "IP(s)"
                    )
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _vm._l(_vm.currentPlan, function(v, k) {
        return _c(
          "v-layout",
          { key: k, attrs: { row: "" } },
          [
            _c(
              "v-flex",
              {
                staticStyle: { "min-width": "140px" },
                attrs: { shrink: "", "py-1": "" }
              },
              [_vm._v(_vm._s(k) + " :")]
            ),
            _c(
              "v-flex",
              {
                staticStyle: { "min-height": "32px", width: "50%" },
                attrs: { grow: "", "py-1": "" }
              },
              [_vm._v(_vm._s(v))]
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }