<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="600px" no-click-animation>
            <div style="width: 100%;height: 100%;position: relative;" v-if="dialog">
                <div style="position: sticky;top: 0;background: #fff;width: 100%;text-align: right;z-index:200;">
                    <v-tabs class="panel-tabs"
                            centered
                            fixed-tabs
                            ripple
                            v-model="active"
                            slider-color="primary">
                        <v-tab v-for="(tab, i) in ['Add','Remove']" :key="i"
                               active-class="tab-active">
                            {{tab}}
                        </v-tab>
                    </v-tabs>
                </div>
                <v-card>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container fluid pa-0>
                            <transiton>
                                <transition
                                        name="slide"
                                        mode="out-in">
                                    <componets ref="addons" :is="mod"></componets>
                                </transition>
                            </transiton>
                        </v-container>
                    </v-card-text>
                </v-card>
                <div style="position: sticky;bottom: 0;background: #fff;width: 100%;text-align: right;border-top: 1px solid #ccc;">
                    <v-btn color="blue darken-1 text-none" flat @click="close">Close</v-btn>
                    <v-btn color="primary text-none" @click="ok" :loading="loading">Submit</v-btn>
                </div>
            </div>
        </v-dialog>
        <SuccessBox ref="successbox"></SuccessBox>
    </div>
</template>

<script>
    import AddPlan from './AddPlan.vue'
    import RemovePlan from './RemovePlan.vue'
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import SuccessBox from '@/components/successBox/SuccessBox'
    import {mapState} from 'vuex'


    export default {
        name: "addOns",
        mixins: [loadingMixin],
        components: {
            AddPlan,
            RemovePlan,
            SuccessBox
        },
        data() {
            return {
                active: 0,
            }
        },
        computed: {
            mod() {
                return this.active ? RemovePlan : AddPlan
            },
            ...mapState('application', ['appInfo'])
        },
        methods: {
            ok() {
                const formData = this.$refs.addons.getFormData()
                if (formData){
                    !this.active?this.sunbmitAddOns(formData): this.submitRemoveOns(formData)
                }
            },
            submitRemoveOns(data) {
                this.loading = true
                this.$http({
                    method: 'post',
                    url: `/packages/${this.appInfo.uuid}/remove_addon/`,
                    data: data
                }).then(() => {
                    this.loading = false
                    this.dialog = false
                    this.$refs.successbox.open(1)
                }).catch((e) => {
                    this.$message.error(e.detail)
                    this.loading = false
                })
            },
            sunbmitAddOns(data) {
                this.loading = true
                this.$http({
                    method: 'post',
                    url: `/packages/${this.appInfo.uuid}/addon/`,
                    data: data
                }).then(res => {
                    this.loading = false
                    if (res.invoice_uuid){
                        this.$router.push(`/invoice-detail/${res.invoice_uuid}`)
                    } else {
                        this.dialog = false
                        this.$refs.successbox.open(1)
                    }
                }).catch((e) => {
                    this.$message.error(e.detail)
                    this.loading = false
                })
            }
        }
    }
</script>

<style lang="scss">

</style>
