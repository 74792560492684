<template>
    <v-container fluid pa-0 class="upgrade-modal">
        <v-form ref="form">
            <v-layout column>
                <v-flex>
                    <p style="color: #666;font-size: 14px;">
                        Please choose your target plan and submit an order first.
                        There will be about 1-3 minutes of downtime during the downgrade.<br>
                        <span v-if="appInfo.has_paypal_subscription">
                            After you change the plan, the future recurring amount will be changed, followed by your PayPal subscription cancellation.
                            If you still need automatic payment through PayPal, please click “PayPal Subscription” on your next invoice.
                        </span>
                    </p>
                </v-flex>
                <v-flex>
                    <CurrentPlan></CurrentPlan>
                    <SmallBlock class="pt-2">
                        <span slot="title" style="color:#333;font-size: 15px;font-weight: bold;">Downgrade to</span>
                        <v-layout row>
                            <v-flex shrink style="min-width: 140px;" py-1>New Plan :</v-flex>
                            <v-flex  py-1 style="min-height: 32px;">
                                <v-select @change="changePlan" v-model="selectedPlan" height="24" :items="plans" return-object
                                          :rules="[value => value.extra.can_use||value.extra.message]"
                                          item-value="uuid"
                                          :disabled="selectedDisabled"  dense>
                                    <template v-slot:no-data>
                                        <span>No downgrade plan</span>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <div style="width: 100%">
                                            {{item.name}}<v-icon v-if="!item.extra.can_use" style="float: right" color="error">info</v-icon>
                                        </div>
                                    </template>
                                    <template v-slot:selection="{item}">
                                        <span :style="{color: item.extra.can_use?'inherit':$vuetify.theme.error}">
                                            {{item.name}}
                                        </span>
                                    </template>
                                </v-select>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex shrink style="min-width: 140px;" >Configuration :</v-flex>
                            <v-flex  style="min-height: 32px;">
                                <span v-if="selectedPlan.total_cpus">{{selectedPlan.total_cpus}} CPU cores</span>
                                <span v-if="selectedPlan.memory_size"> / {{parseInt(selectedPlan.memory_size)}}GB RAM</span>
                                <span v-if="selectedPlan.disk_size"> / {{parseInt(selectedPlan.disk_size)}}GB SSD</span>
                                <span v-if="selectedPlan.backup_storage_size"> / {{parseInt(selectedPlan.backup_storage_size)}}GB Backup</span>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex shrink style="min-width: 140px;" py-1>Billing Cycle :</v-flex>
                            <v-flex  py-1 style="min-height: 32px;">
                                <v-select v-model="selectedPrice" height="24" :items="currentPrice"
                                          :disabled="!selectedPlan" item-value="duration" return-object hide-details dense>
                                    <template v-slot:item="{item}">
                                        <span>
                                            {{item.duration_description}} : $ {{item.price_base}}/month
                                        </span>
                                    </template>
                                    <template v-slot:selection="{item}">
                                        <span>
                                            {{item.duration_description}} : $ {{item.price_base}}/month
                                        </span>
                                    </template>
                                    <template v-slot:no-data>
                                        <span>Please choose plan first</span>
                                    </template>
                                </v-select>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex shrink style="min-width: 140px;" py-1>Recurring Amount :</v-flex>
                            <v-flex v-if="selectedPrice" grow py-1 style="min-height: 32px;">$ {{(selectedPrice.recurring_amount+totalAmount).toFixed(2)}}</v-flex>
                        </v-layout>
                    </SmallBlock>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
    import CurrentPlan from '../_source/CurrentPlan'
    import SmallBlock from '@/components/SmallBlock'
    import {mapState,mapActions} from 'vuex'

    export default {
        name: "Downgrade",
        components : {SmallBlock,CurrentPlan},
        data () {
            return {
                selectedDisabled: true,
                migrateFee: 0,
                selectedPrice: '',
                selectedPlan: '',
                selectedMethod: '',
                plans: [],
                refundMethods: [],
                alreadyAddonFee: 0
            }
        },
        computed: {
            currentPrice() {
                if (!this.selectedPlan) {
                    return []
                }
                const plan = this.plans.find(item => {
                    return item.uuid === this.selectedPlan.uuid
                })
                return plan.prices_set
            },
            totalAmount() {
                return this.alreadyAddonFee / this.appInfo.price.duration * this.selectedPrice.duration
            },
            ...mapState('application', ['appInfo']),
            ...mapActions('application',['getCurrentAddons'])
        },
        methods: {
            getDowngradePlan() {
                this.selectedDisabled = true
                this.$http.get(`/packages/${this.appInfo.uuid}/get_available_plan/`, {
                    params: {
                        action: 'Downgrade'
                    }
                }).then(res => {
                    this.selectedDisabled = false
                    this.plans = res
                })
            },
            getAlreadyAddons() {
                this.getCurrentAddons.then(res => {
                    this.alreadyAddonFee = res.already_addons_amount
                }).catch(err => {
                    this.$message.error(err.detail)
                })
            },
            changePlan() {
                this.migrateFee = 0
                this.selectedPrice = ''
            },
            getFormData() {
                if (!this.selectedPlan.uuid || !this.selectedPrice.duration || !this.$refs.form.validate()) {
                    return false
                } else {
                    return {
                        new_plan_uuid: this.selectedPlan.uuid,
                        new_duration: this.selectedPrice.duration,
                    }
                }
            },
        },
        created() {
            this.getDowngradePlan()
            this.getAlreadyAddons()
        }
    }
</script>

<style lang="scss">
    .upgrade-modal{
        .v-select{
            font-size: 15px;
            padding-top: 0;
            margin-top: 0;
            top: -2px;
        }
    }
</style>
