var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "pa-0": "" } },
    [
      _c(
        "v-form",
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c("v-flex", [
                _c(
                  "p",
                  { staticStyle: { color: "#666", "font-size": "14px" } },
                  [
                    _vm._v(
                      "\n                    Please choose the add-on items and set the amount you'd like to remove.\n                    Then click the submit button. There will be about 1-3 minutes of downtime during the downgrade."
                    ),
                    _c("br"),
                    _vm.appInfo.has_paypal_subscription
                      ? _c("span", [
                          _vm._v(
                            "\n                        After you update add-on configurations, the future recurring amount will be changed, followed by your PayPal subscription cancellation.\n                        If you still need automatic payment through PayPal, please click “PayPal Subscription” on your next invoice.\n                    "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _c(
                "v-flex",
                [
                  _c("CurrentPlan"),
                  _c(
                    "SmallBlock",
                    { staticClass: "pt-2" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#333",
                            "font-size": "15px",
                            "font-weight": "bold"
                          },
                          attrs: { slot: "title" },
                          slot: "title"
                        },
                        [_vm._v("Add-on Items")]
                      ),
                      _vm._l(_vm.removeOns, function(v, k) {
                        return _c(
                          "v-layout",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: k !== "dedicated_ip",
                                expression: "k!=='dedicated_ip'"
                              }
                            ],
                            key: k,
                            staticStyle: { height: "50px" },
                            attrs: { row: "" }
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                staticStyle: { width: "300px" },
                                attrs: { grow: "" }
                              },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    disabled: !v.value.length,
                                    height: "24"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "15px"
                                                }
                                              },
                                              [_vm._v(_vm._s(v.description))]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: v.flag,
                                    callback: function($$v) {
                                      _vm.$set(v, "flag", $$v)
                                    },
                                    expression: "v.flag"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticStyle: { "min-height": "32px" },
                                attrs: { shrink: "", "ml-2": "" }
                              },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    height: "24",
                                    disabled: !v.flag,
                                    "hide-details": "",
                                    dense: "",
                                    items: v.value
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.removeonsUnit(item, k)
                                                  ) +
                                                  "\n                                    "
                                              )
                                            ])
                                          ]
                                        }
                                      },
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.removeonsUnit(item, k)
                                                  ) +
                                                  "\n                                    "
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: v.selected,
                                    callback: function($$v) {
                                      _vm.$set(v, "selected", $$v)
                                    },
                                    expression: "v.selected"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-layout",
                        { attrs: { row: "", "mt-3": "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: {
                                "min-width": "140px",
                                "min-height": "32px"
                              },
                              attrs: { shrink: "", "py-1": "" }
                            },
                            [_vm._v("Recurring Amount :")]
                          ),
                          _vm.addonsRecurringAmount && !_vm.loading
                            ? _c(
                                "v-flex",
                                {
                                  staticStyle: { "min-height": "32px" },
                                  attrs: { grow: "", "py-1": "" }
                                },
                                [
                                  _vm._v(
                                    "$ " + _vm._s(_vm.addonsRecurringAmount)
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.loading
                            ? _c("v-progress-circular", {
                                staticStyle: { "min-height": "32px" },
                                attrs: { indeterminate: "", width: 3, size: 25 }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }