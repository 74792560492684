<template>
    <TabCard title="Real-Time Status" class="real-status-block">
        <v-layout column>
            <v-flex>
                <p class="fs-3 font-weight-regular" style="font-size: 14px;color: #666;margin: 0;">Each instance has
                    dedicated resources. The
                    following shows each instance's status and resource usage.</p>
            </v-flex>
            <v-flex>
                <v-data-table
                        :headers="headers"
                        :items="instances"
                        hide-actions
                        class="elevation-0 source-table">
                    <template v-slot:items="{item}">
                        <td>
                            {{ item.name }}
                        </td>
                        <td v-if="item.type">
                            {{ item.type }}
                        </td>
                        <td>
                            <v-layout column>
                                <v-flex class="pb-0 pt-4">
                                    {{memoryString(item.used_memory,item.total_memory,true)}}
                                </v-flex>
                                <v-flex class="pt-0">
                                    <v-progress-linear
                                            :value="Math.round(((item.used_memory/1024)/item.total_memory)*100)"
                                            height="5" color="blue"
                                            class="elevation-2"></v-progress-linear>
                                </v-flex>
                            </v-layout>
                        </td>
                        <td>
                            <v-layout column>
                                <v-flex class="pb-0 pt-4">
                                    {{memoryString(item.used_disk,item.total_disk)}}
                                </v-flex>
                                <v-flex class="pt-0">
                                    <v-progress-linear
                                            :value="Math.round((item.used_disk/item.total_disk)*100)"
                                            height="5" color="blue"
                                            class="elevation-2"></v-progress-linear>
                                </v-flex>
                            </v-layout>
                        </td>
                        <td>
                            <span style="color: #028e01" v-if="item.status=== 'Running'">Running</span>
                            <span style="color: #ff5a01" v-if="item.status=== 'Startup'">Startup</span>
                            <span style="color: #8e0000;" v-if="item.status=== 'Failed'">Failed</span>
                        </td>
                    </template>
                    <template v-slot:no-data>
                        <div style="min-height: 192px;position: relative;">
                            <Empty></Empty>
                        </div>
                    </template>
                </v-data-table>
            </v-flex>
            <v-flex>
                <v-subheader class="panel-title">Backup Disk Space</v-subheader>
                <v-layout wrap>
                    <v-flex xs12 sm5>
                        <p style="color: #666;margin: 0;" class="fs-3 font-weight-regular">Dedicated for backups of this
                            deployment</p>
                    </v-flex>
                    <v-flex xs12 sm7>
                        <v-layout row>
                            <v-flex shrink class="fs-3 font-weight-bold" style="align-self: center;">
                                {{memoryString(runningStatus.used_backup_disk,runningStatus.total_backup_disk)}}
                            </v-flex>
                            <v-flex grow>
                                <v-progress-circular
                                        v-if="!!runningStatus.total_backup_disk"
                                        :rotate="-90"
                                        :size="100"
                                        :width="15"
                                        :value="(runningStatus.used_backup_disk/runningStatus.total_backup_disk)*100"
                                        color="light-blue">
                                    {{ value }}
                                </v-progress-circular>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
<!--            <v-flex>-->
<!--                <v-btn class="mx-0 text-none" color="primary" @click="$refs.appCheck.open()">Check</v-btn>-->
<!--            </v-flex>-->
        </v-layout>
        <AppCheck></AppCheck>
    </TabCard>
</template>

<script>
    import TabCard from '@/components/TabCard.vue'
    import Empty from '@/components/Empty.vue'
    import AppCheck from './_source/AppCheck.vue'
    import {mapState} from "vuex";

    import {memoryFilter} from '@/module/filters/filter'
    export default {
        name: "RealStatus",
        components : {TabCard ,Empty,AppCheck},
        data (){
            return {
                headersList: [
                    {text: 'Instances', value: '', sortable: false, align: 'left'},
                    {text: 'Type', value: '', sortable: false, align: 'left'},
                    {text: 'RAM', value: '', sortable: false, align: 'left',},
                    {text: 'Disk Space', value: '', sortable: false, align: 'left',},
                    {text: 'Status', value: '', sortable: false, align: 'left',},
                ]
            }
        },
        computed: {
            memoryString() {
                return (used, total,flag) => {
                    if(flag){
                        return `${used}MB out of ${memoryFilter(total)}`
                    }else{
                        return `${memoryFilter(used)} out of ${memoryFilter(total)}`
                    }
                }
            },
            runningStatus() {
                return {
                    total_backup_disk: this.stats.total_backup_disk || 0,
                    used_backup_disk: this.stats.used_backup_disk || 0,
                    instance_status: this.stats.instance_status || []
                }
            },
            instances() {
                return this.runningStatus.instance_status
            },
            headers() {
                let arr = this.headersList.slice(0)
                if (this.instances.length !== 0 && !this.instances[0].type) {
                    arr.splice(1, 1)
                }
                return arr
            },
            ...mapState('application', [ 'stats']),
        }
    }
</script>

<style lang="scss">
    .real-status-block{
        .source-table {
            thead, tbody {
                tr {
                    border-bottom: 0px solid transparent !important;
                }
            }
        }
    }
</style>