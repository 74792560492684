<template>
    <TabCard title="Stop Using The Application">
        <v-layout column>
            <v-flex>
                <p v-if="!hadRequest" class="fs-3 font-weight-regular" style="color: #666;margin: 0;">Before terminating the deployment, please make sure that you have exported your data or backed up them on your local machine.</p>
                <p v-else class="fs-3 font-weight-regular" style="color: #666;margin: 0;">You have submitted a cancellation request for the service. If you want to continue to use the serivce, please delete the request.</p>
            </v-flex>
            <v-flex style="padding : 10px 0;" v-if="ready">
                <template v-if="!hadRequest">
                    <v-btn :title="isFree==='Delete Deployment'?'Stop using the deployment.': 'Submit a request to cancel the application.'" class="text-none" color="error" @click="$refs.cancellation.open()"> {{isFree?'Delete Deployment':'Request Cancellation'}}</v-btn>
                </template>
                <template v-else>
                    <v-btn class="text-none" color="error" @click="$refs.cancelSubmit.open()">Delete Cancellation Request</v-btn>
                </template>
            </v-flex>
        </v-layout>
        <StepCancelModal ref="cancellation"  @confirm="cancel" :is-free="isFree"></StepCancelModal>
        <Confirm ref="cancelSubmit" @confirm="cancelSubmit">
            <span slot="title">Confirmation</span>
            <template v-slot:content>
                Are you sure you want to cancel the cancellation request for <span :style="{color: $vuetify.theme.primary}">{{ appInfo.name}}</span>
            </template>
        </Confirm>
    </TabCard>
</template>

<script>
    import StepCancelModal from './StepCancelModal.vue'
    import TabCard from '@/components/TabCard.vue'
    import Confirm from '@/components/Confirm.vue'
    import {mapState }   from 'vuex' //mapState  mapGetters mapActions mapGetters
    export default {
        name: "Cancellation",
        components: {
             StepCancelModal,Confirm,TabCard
        },
        data (){
            return {
                ready : false,
                requestStatus: []
            }
        },
        computed: {
            isFree (){
                return this.appInfo.is_free
            },
            filterArr(){
                let arr =   this.requestStatus.filter((o)=> {
                    return ['Cancelled','Closed'].indexOf(o.status)  === -1
                })
                return arr
            },
            hadRequest(){
                return this.filterArr.length !== 0
            },
            cancellationRequestUUID (){
                return this.filterArr.length===0 ? '':this.filterArr[0].uuid
            },
            ...mapState('application', [ 'appInfo'])
        },
        methods: {
            getRequestTypes() {
                 this.$http.get('/cancellation-requests/',{
                     params : {
                         package_uuid : this.appInfo.uuid
                     }
                 }).then((res) => {
                    this.ready = true
                    this.requestStatus = res.results
                    return res
                }).catch(() => {
                    this.$message.error('Oops, something wrong happened while canceling your application. You can reach us at support@cloudclusters.io for this issue.')
                })
            },
            cancel({requestType ,description ,reasons,need_check}){
               return  this.$http.post(`/cancellation-requests/`,{
                    reasons,
                    cancellation_type: requestType,
                    package_uuid: this.appInfo.uuid,
                    notes: description,
                }).then( () => {
                    if(this.isFree && !need_check){
                        this.$router.push(`/applications`)
                    }else{
                        this.getRequestTypes()
                    }
                })
            },
            cancelSubmit(){
               return  this.$http.delete(`/cancellation-requests/${this.cancellationRequestUUID}/`).then(() => {
                    this.getRequestTypes()
                   this.$message.success('Cancellation of your application succeed.')
                }).catch((e) => {
                    this.$message.error('Oops, your service cancellation failed. You can reach us at support@cloudclusters.io for this issue.')
                    return Promise.reject(e)
               })
            },
        },
        created(){
            this.getRequestTypes()
        }
    }
</script>

<style lang="scss">

</style>
