<template>
    <v-container fluid pa-0 class="upgrade-modal">
        <v-form>
            <v-layout column>
                <v-flex>
                    <p style="color: #666;font-size: 15px;">Please choose your target plan and submit an order first.
                        There will be about 1-3 minutes of downtime during the upgrade.<br>
                        <span v-if="appInfo.has_paypal_subscription">
                            After you change the plan, the future recurring amount will be changed, followed by your PayPal subscription cancellation.
                            If you still need automatic payment through PayPal, please click “PayPal Subscription” on your next invoice.
                        </span></p>
                </v-flex>
                <v-flex>
                    <CurrentPlan></CurrentPlan>
                    <SmallBlock class="pt-2">
                        <span slot="title" style="color:#333;font-size: 15px;font-weight: bold;">Upgrade to</span>
                        <v-layout row>
                            <v-flex shrink style="min-width: 140px;" py-1>New Plan :</v-flex>
                            <v-flex grow py-1 style="min-height: 32px;width: 200px;">
                                <v-select @change="changePlan" v-model="selectedPlan" :disabled="selectDisabled"
                                          height="24" :items="plans" return-object
                                          item-text="name" hide-details dense>
                                    <template v-slot:no-data>
                                        <span>No upgrade plan</span>
                                    </template>
                                </v-select>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex shrink style="min-width: 140px;" py-1>Configuration :</v-flex>
                            <v-flex grow py-1 style="min-height: 32px; width: 50%">
                                <span v-if="selectedPlan.total_cpus">{{selectedPlan.total_cpus}} CPU Core(s)</span>
                                <span v-if="selectedPlan.memory_size"> / {{parseInt(selectedPlan.memory_size)}}GB RAM</span>
                                <span v-if="selectedPlan.disk_size"> / {{parseInt(selectedPlan.disk_size)}}GB SSD</span>
                                <span v-if="selectedPlan.backup_storage_size"> / {{parseInt(selectedPlan.backup_storage_size)}}GB Backup</span>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex shrink style="min-width: 140px;" py-1>Billing Cycle :</v-flex>
                            <v-flex grow py-1 style="min-height: 32px;width: 200px;">
                                <v-select v-model="selectedPrice" :disabled="selectDisabled" height="24"
                                          :items="currentPrice"
                                          item-value="duration"
                                          @change="getMigrateFee" return-object hide-details dense>
                                    <template v-slot:item="{item}">
                                        <span>
                                            {{item.duration_description}} : $ {{item.price_base}}/month
                                        </span>
                                    </template>
                                    <template v-slot:selection="{item}">
                                        <span>
                                            {{item.duration_description}} : $ {{item.price_base}}/month
                                        </span>
                                    </template>
                                    <template v-slot:no-data>
                                        <span>Please select plan first</span>
                                    </template>
                                </v-select>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex shrink style="min-width: 140px;;min-height: 32px;" py-1>Recurring Amount :</v-flex>
                            <v-flex v-if="selectedPrice" grow py-1 style="min-height: 32px;">$
                                {{(selectedPrice.recurring_amount+totalAmount).toFixed(2)}}
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex style="min-width: 140px;min-height: 32px;" py-1 shrink>
                                Total Due Today
                                <v-tooltip bottom nudge-right="80">
                                    <template v-slot:activator="{ on }">
                                        <i class="fa fa-info-circle" aria-hidden="true" v-on="on"></i>
                                    </template>
                                    <span>The price differential before the app's next due date.</span>
                                </v-tooltip>
                                :
                            </v-flex>
                            <v-flex v-if="migrateFee && !loading" py-1 style="min-height: 32px;">$ {{migrateFee}}
                            </v-flex>
                            <v-progress-circular v-if="loading" style="min-height: 32px;" indeterminate :width="3"
                                                 :size="25"></v-progress-circular>
                        </v-layout>
                    </SmallBlock>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
    import CurrentPlan from '../_source/CurrentPlan'
    import SmallBlock from '@/components/SmallBlock'
    import {mapState, mapActions} from 'vuex'

    export default {
        name: "Upgrade",
        components: {SmallBlock, CurrentPlan},
        data() {
            return {
                loading: false,
                selectDisabled: true,
                migrateFee: 0,
                selectedPrice: '',
                selectedPlan: '',
                plans: [],
                alreadyAddonFee: 0
            }
        },
        computed: {
            currentPrice() {
                if (!this.selectedPlan) {
                    return []
                }
                const plan = this.plans.find(item => {
                    return item.uuid === this.selectedPlan.uuid
                })
                return plan.prices_set
            },
            totalAmount() {
                return this.alreadyAddonFee / this.appInfo.price.duration * this.selectedPrice.duration
            },
            ...mapState('application', ['appInfo']),
            ...mapActions('application', ['getCurrentAddons'])
        },
        methods: {
            getUpradePlan() {
                this.selectDisabled = true
                this.$http.get(`/packages/${this.appInfo.uuid}/get_available_plan/`, {
                    params: {
                        action: 'Upgrade'

                    }
                }).then(res => {
                    this.selectDisabled = false
                    this.plans = res
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            getAlreadyAddons() {
                this.getCurrentAddons.then(res => {
                    this.alreadyAddonFee = res.already_addons_amount
                }).catch(err => {
                    this.$message.error(err.detail)
                })
            },
            changePlan() {
                this.migrateFee = 0
                this.selectedPrice = ''
            },
            getMigrateFee() {
                this.loading = true
                this.$http.get(`/packages/${this.appInfo.uuid}/get_migrate_fee/`, {
                    params: {
                        new_plan_uuid: this.selectedPlan.uuid,
                        new_duration: this.selectedPrice.duration
                    }
                }).then(res => {
                    if (res.migrate_fee > 2) {
                        this.migrateFee = res.migrate_fee.toFixed(2)
                    } else if (res.migrate_fee > 0) {
                        this.migrateFee = '0.00  The price differential before next due date is waived this time. The auto-upgrade will start once the order is submitted.'
                    } else {
                        this.migrateFee = '0.00  No price differential needed when your app is a free trial one. The auto-upgrade will start once the order is submitted.'
                    }
                    this.loading = false
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            getFormData() {
                if (!this.selectedPlan.uuid || !this.selectedPrice.duration) {
                    return false
                } else {
                    return {
                        new_plan_uuid: this.selectedPlan.uuid,
                        new_duration: this.selectedPrice.duration
                    }
                }

            }
        },
        created() {
            this.getUpradePlan()
            this.getAlreadyAddons()
        }
    }
</script>

<style lang="scss">
    .upgrade-modal {
        .v-select {
            font-size: 15px;
            padding-top: 0;
            margin-top: 0;
            top: -2px;
        }
    }
</style>
